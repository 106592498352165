
/**
 * Generated by generateOperationKeys.js
 *
 * DO NOT EDIT THIS FILE DIRECTLY
 * 
 * Run 'yarn generate:api' to update this file
 */

export const apiEndpoints = {
  'getInvite': {
    path: '/auth/invites/{inviteCode}',
    operationId: 'getInvite',
  },
  'requestInvite': {
    path: '/auth/invites',
    operationId: 'requestInvite',
  },
  'signin': {
    path: '/auth/signins',
    operationId: 'signin',
  },
  'signinSecurityCode': {
    path: '/auth/signins/{signinId}/security-code',
    operationId: 'signinSecurityCode',
  },
  'resendSecurityCode': {
    path: '/auth/signins/{signinId}/security-code',
    operationId: 'resendSecurityCode',
  },
  'signinOtp': {
    path: '/auth/signins/{signinId}/otp',
    operationId: 'signinOtp',
  },
  'finalizeSignin': {
    path: '/auth/signins/{signinId}/finalize',
    operationId: 'finalizeSignin',
  },
  'resetPassword': {
    path: '/auth/signins/{signinId}/reset-password',
    operationId: 'resetPassword',
  },
  'resubscribe': {
    path: '/auth/signins/{signinId}/resubscribe',
    operationId: 'resubscribe',
  },
  'signup': {
    path: '/auth/signup',
    operationId: 'signup',
  },
  'getUser': {
    path: '/user',
    operationId: 'getUser',
  },
  'updateUser': {
    path: '/user',
    operationId: 'updateUser',
  },
  'updatePassword': {
    path: '/user/password',
    operationId: 'updatePassword',
  },
  'signout': {
    path: '/user/session/signout',
    operationId: 'signout',
  },
  'renewSession': {
    path: '/user/session/renew',
    operationId: 'renewSession',
  },
  'getGroupPrivileges': {
    path: '/user/groups/{groupId}/privileges',
    operationId: 'getGroupPrivileges',
  },
  'deactivate': {
    path: '/user/deactivate',
    operationId: 'deactivate',
  },
  'requestChange': {
    path: '/user/changes',
    operationId: 'requestChange',
  },
  'confirmChange': {
    path: '/user/changes/{confirmationId}',
    operationId: 'confirmChange',
  },
  'enableMfaSms': {
    path: '/user/mfa/sms/enable',
    operationId: 'enableMfaSms',
  },
  'confirmEnableMfaSms': {
    path: '/user/mfa/sms/enable/{confirmationId}',
    operationId: 'confirmEnableMfaSms',
  },
  'disableMfaSms': {
    path: '/user/mfa/sms/disable',
    operationId: 'disableMfaSms',
  },
  'confirmDisableMfaSms': {
    path: '/user/mfa/sms/disable/{confirmationId}',
    operationId: 'confirmDisableMfaSms',
  },
  'enableMfaOtp': {
    path: '/user/mfa/otp/enable',
    operationId: 'enableMfaOtp',
  },
  'confirmEnableMfaOtp': {
    path: '/user/mfa/otp/enable/{confirmationId}',
    operationId: 'confirmEnableMfaOtp',
  },
  'disableMfaOtp': {
    path: '/user/mfa/otp/disable',
    operationId: 'disableMfaOtp',
  },
  'confirmDisableMfaOtp': {
    path: '/user/mfa/otp/disable/{confirmationId}',
    operationId: 'confirmDisableMfaOtp',
  },
  'createAccessToken': {
    path: '/auth/oauth2/token',
    operationId: 'createAccessToken',
  },
  'createMilestones': {
    path: '/selectsmart/milestones',
    operationId: 'createMilestones',
  },
  'getEmployerBenefitsYear': {
    path: '/selectsmart/employers/{employerId}/{planYear}',
    operationId: 'getEmployerBenefitsYear',
  },
  'getEmployer': {
    path: '/selectsmart/employers/{employerId}',
    operationId: 'getEmployer',
  },
  'getBenefitsYear': {
    path: '/selectsmart/employer',
    operationId: 'getBenefitsYear',
  },
  'createPerson': {
    path: '/selectsmart/people',
    operationId: 'createPerson',
  },
  'getPeople': {
    path: '/selectsmart/people',
    operationId: 'getPeople',
  },
  'updatePerson': {
    path: '/selectsmart/people/{personId}',
    operationId: 'updatePerson',
  },
  'getPerson': {
    path: '/selectsmart/people/{personId}',
    operationId: 'getPerson',
  },
  'deletePerson': {
    path: '/selectsmart/people/{personId}',
    operationId: 'deletePerson',
  },
  'updateHousehold': {
    path: '/selectsmart/household',
    operationId: 'updateHousehold',
  },
  'getHousehold': {
    path: '/selectsmart/household',
    operationId: 'getHousehold',
  },
  'getHouseholdIncomeLevels': {
    path: '/selectsmart/household/income-levels',
    operationId: 'getHouseholdIncomeLevels',
  },
  'setIncome': {
    path: '/selectsmart/incomes/{personId}',
    operationId: 'setIncome',
  },
  'updateIncome': {
    path: '/selectsmart/incomes/{personId}',
    operationId: 'updateIncome',
  },
  'getIncome': {
    path: '/selectsmart/incomes/{personId}',
    operationId: 'getIncome',
  },
  'deleteIncome': {
    path: '/selectsmart/incomes/{personId}',
    operationId: 'deleteIncome',
  },
  'getIncomes': {
    path: '/selectsmart/incomes',
    operationId: 'getIncomes',
  },
  'setTobaccoUse': {
    path: '/selectsmart/tobacco-use/{personId}',
    operationId: 'setTobaccoUse',
  },
  'updateTobaccoUse': {
    path: '/selectsmart/tobacco-use/{personId}',
    operationId: 'updateTobaccoUse',
  },
  'getTobaccoUse': {
    path: '/selectsmart/tobacco-use/{personId}',
    operationId: 'getTobaccoUse',
  },
  'deleteTobaccoUse': {
    path: '/selectsmart/tobacco-use/{personId}',
    operationId: 'deleteTobaccoUse',
  },
  'getTobaccoUses': {
    path: '/selectsmart/tobacco-use',
    operationId: 'getTobaccoUses',
  },
  'setHealthcare': {
    path: '/selectsmart/healthcare/{personId}',
    operationId: 'setHealthcare',
  },
  'updateHealthcare': {
    path: '/selectsmart/healthcare/{personId}',
    operationId: 'updateHealthcare',
  },
  'getHealthcare': {
    path: '/selectsmart/healthcare/{personId}',
    operationId: 'getHealthcare',
  },
  'deleteHealthcare': {
    path: '/selectsmart/healthcare/{personId}',
    operationId: 'deleteHealthcare',
  },
  'getHealthcares': {
    path: '/selectsmart/healthcare',
    operationId: 'getHealthcares',
  },
  'getUsageLevelAssumptions': {
    path: '/selectsmart/assumptions/usage-levels',
    operationId: 'getUsageLevelAssumptions',
  },
  'getTypicalServices': {
    path: '/selectsmart/assumptions/typical-services',
    operationId: 'getTypicalServices',
  },
  'createPlan': {
    path: '/selectsmart/plans',
    operationId: 'createPlan',
  },
  'getPlans': {
    path: '/selectsmart/plans',
    operationId: 'getPlans',
  },
  'updatePlan': {
    path: '/selectsmart/plans/{planId}',
    operationId: 'updatePlan',
  },
  'getPlan': {
    path: '/selectsmart/plans/{planId}',
    operationId: 'getPlan',
  },
  'deletePlan': {
    path: '/selectsmart/plans/{planId}',
    operationId: 'deletePlan',
  },
  'createBenefit': {
    path: '/selectsmart/benefits',
    operationId: 'createBenefit',
  },
  'getBenefits': {
    path: '/selectsmart/benefits',
    operationId: 'getBenefits',
  },
  'getEligibleBenefitTypes': {
    path: '/selectsmart/benefit-types',
    operationId: 'getEligibleBenefitTypes',
  },
  'updateBenefit': {
    path: '/selectsmart/benefits/{benefitId}',
    operationId: 'updateBenefit',
  },
  'getBenefit': {
    path: '/selectsmart/benefits/{benefitId}',
    operationId: 'getBenefit',
  },
  'deleteBenefit': {
    path: '/selectsmart/benefits/{benefitId}',
    operationId: 'deleteBenefit',
  },
  'getBenefitUses': {
    path: '/selectsmart/benefit-uses',
    operationId: 'getBenefitUses',
  },
  'updateBenefitUse': {
    path: '/selectsmart/benefit-uses/{benefitUseId}',
    operationId: 'updateBenefitUse',
  },
  'getBenefitUse': {
    path: '/selectsmart/benefit-uses/{benefitUseId}',
    operationId: 'getBenefitUse',
  },
  'deleteBenefitUse': {
    path: '/selectsmart/benefit-uses/{benefitUseId}',
    operationId: 'deleteBenefitUse',
  },
  'requestBundles': {
    path: '/selectsmart/bundles',
    operationId: 'requestBundles',
  },
  'getLatestBundles': {
    path: '/selectsmart/bundles',
    operationId: 'getLatestBundles',
  },
  'getBundles': {
    path: '/selectsmart/bundles/{bundlesId}',
    operationId: 'getBundles',
  },
  'setBundle': {
    path: '/selectsmart/bundles/{bundlesId}/{bundleKey}',
    operationId: 'setBundle',
  },
  'getSelectedBundle': {
    path: '/selectsmart/bundle',
    operationId: 'getSelectedBundle',
  },
  'getAccounts': {
    path: '/selectsmart/accounts',
    operationId: 'getAccounts',
  },
  'refreshAccounts': {
    path: '/selectsmart/accounts',
    operationId: 'refreshAccounts',
  },
  'createAccount': {
    path: '/selectsmart/accounts',
    operationId: 'createAccount',
  },
  'getAccount': {
    path: '/selectsmart/accounts/{accountId}',
    operationId: 'getAccount',
  },
  'updateAccount': {
    path: '/selectsmart/accounts/{accountId}',
    operationId: 'updateAccount',
  },
  'deleteAccount': {
    path: '/selectsmart/accounts/{accountId}',
    operationId: 'deleteAccount',
  },
  'getGoals': {
    path: '/selectsmart/goals',
    operationId: 'getGoals',
  },
  'refreshGoals': {
    path: '/selectsmart/goals',
    operationId: 'refreshGoals',
  },
  'getGoal': {
    path: '/selectsmart/goals/{goalType}',
    operationId: 'getGoal',
  },
  'updateGoal': {
    path: '/selectsmart/goals/{goalType}',
    operationId: 'updateGoal',
  },
  'requestNextdollar': {
    path: '/selectsmart/nextdollar',
    operationId: 'requestNextdollar',
  },
  'getLastestNextdollar': {
    path: '/selectsmart/nextdollar',
    operationId: 'getLastestNextdollar',
  },
  'cancelNextdollar': {
    path: '/selectsmart/nextdollar/{nextdollarId}',
    operationId: 'cancelNextdollar',
  },
  'getNextdollar': {
    path: '/selectsmart/nextdollar/{nextdollarId}',
    operationId: 'getNextdollar',
  },
  'updateNextdollar': {
    path: '/selectsmart/nextdollar/{nextdollarId}',
    operationId: 'updateNextdollar',
  },
  'createActionplan': {
    path: '/selectsmart/actionplan',
    operationId: 'createActionplan',
  },
  'getActionplan': {
    path: '/selectsmart/actionplan',
    operationId: 'getActionplan',
  },
  'sendActionplanEmail': {
    path: '/selectsmart/actionplan/send-email',
    operationId: 'sendActionplanEmail',
  },
  'updateActionsgroup': {
    path: '/selectsmart/actionplans/{actionplanId}/{actionsGroup}',
    operationId: 'updateActionsgroup',
  },
  'loadHousehold': {
    path: '/selectsmart/integrations',
    operationId: 'loadHousehold',
  },
  'submitVoyaChangeDeprecated': {
    path: '/selectsmart/integrations/voya',
    operationId: 'submitVoyaChangeDeprecated',
  },
  'submitVoyaChange': {
    path: '/selectsmart/integrations/voya/contribution-change',
    operationId: 'submitVoyaChange',
  },
  'collectVoyaEnrollNudge': {
    path: '/selectsmart/integrations/voya/enroll-nudge',
    operationId: 'collectVoyaEnrollNudge',
  },
  'getPlanbenefits': {
    path: '/selectsmart/planbenefits',
    operationId: 'getPlanbenefits',
  },
  'createPlanbenefit': {
    path: '/selectsmart/planbenefits',
    operationId: 'createPlanbenefit',
  },
  'getPlanbenefit': {
    path: '/selectsmart/planbenefits/{planbenefitId}',
    operationId: 'getPlanbenefit',
  },
  'deletePlanbenefit': {
    path: '/selectsmart/planbenefits/{planbenefitId}',
    operationId: 'deletePlanbenefit',
  },
  'updatePlanbenefit': {
    path: '/selectsmart/planbenefits/{planbenefitId}',
    operationId: 'updatePlanbenefit',
  },
  'getBenefitssummary': {
    path: '/selectsmart/benefits-summary',
    operationId: 'getBenefitssummary',
  },
  'getActions': {
    path: '/selectsmart/actions',
    operationId: 'getActions',
  },
  'updateActions': {
    path: '/selectsmart/actions/{actionId}',
    operationId: 'updateActions',
  },
  'submitBenefitfocusBundle': {
    path: '/selectsmart/integrations/benefitfocus/submit-bundle/{bundlesId}/{bundleKey}',
    operationId: 'submitBenefitfocusBundle',
  },
  'updateOptin': {
    path: '/selectsmart/claims/optin',
    operationId: 'updateOptin',
  },
  'refreshClaimsSummary': {
    path: '/selectsmart/claims/summary',
    operationId: 'refreshClaimsSummary',
  },
  'getClaimsSummary': {
    path: '/selectsmart/claims/summary',
    operationId: 'getClaimsSummary',
  },
  'refreshLdi': {
    path: '/selectsmart/ldi',
    operationId: 'refreshLdi',
  },
  'getLdi': {
    path: '/selectsmart/ldi',
    operationId: 'getLdi',
  },
  'setLdiSelections': {
    path: '/selectsmart/ldi',
    operationId: 'setLdiSelections',
  },
  'setSummarizedBenefits': {
    path: '/selectsmart/summarized-benefits',
    operationId: 'setSummarizedBenefits',
  },
  'updateSummarizedBenefits': {
    path: '/selectsmart/summarized-benefits',
    operationId: 'updateSummarizedBenefits',
  },
  'getSummarizedBenefits': {
    path: '/selectsmart/summarized-benefits',
    operationId: 'getSummarizedBenefits',
  },
  'deleteSummarizedBenefits': {
    path: '/selectsmart/summarized-benefits',
    operationId: 'deleteSummarizedBenefits',
  },
  'summarizedBenefitsEstimate': {
    path: '/selectsmart/summarized-benefits/estimate',
    operationId: 'summarizedBenefitsEstimate',
  },
  'irsLimits': {
    path: '/selectsmart/irs-limits',
    operationId: 'irsLimits',
  },
  }
