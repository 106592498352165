import { Typography } from '@mui/material';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AvailableFormStepsProvider } from './components/form/AvailableFormStepsProvider';
import { Store } from './components/store';
import { RootNavigation } from './RootNavigation';
import { ThemeProvider } from 'context/theme';
import { AppProvider } from 'context/app';
import FontsLoader from 'components/common/FontsLoader';
import { View } from 'components/common/View';
import IsMobileAppEmbeddedProvider from 'context/IsMobileAppEmbeddedProvider';
import { AuthenticationProvider } from 'auth/AuthenticationProvider';
import { MidyearUpdateProvider } from 'context/midyearUpdate';
import { ErrorPage } from 'ErrorPage';
import { ErrorBoundary } from '@sentry/react';
import I18nCustomProvider from './I18nCustomProvider';
import { msg, Trans } from '@lingui/macro';
import DebugTools from 'components/debugTools';
import { DebugProvider } from 'context/debug';
import { EventTrackingProvider } from 'context/eventTracking';
import { FeaturesProvider } from 'context/FeaturesProvider';
import { useLingui } from '@lingui/react';

declare global {
  interface Window {
    AUTOMATED_TEST_ENV?: boolean;
    AUTOMATED_TEST_ONDATE?: string;
    harContextCheckpoint?: number;
    harContextLocation?: string;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //suspense: true,
      //useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  },
});

const muiStyles = {
  BrowserCompatibilityOuter: {
    flex: 1,
    marginTop: 100,
    alignItems: 'center',
    fontFamily: 'sans-serif',
  },
  BrowserCompatibilityInner: {
    alignItems: 'center',
    maxWidth: 600,
  },
  BrowserCompatibilityText: {
    fontFamily: 'sans-serif',
  },
};

const BrowserCompatibility = ({
  children,
}: {
  children: React.JSX.Element;
}) => {
  const { _ } = useLingui();
  // If the title is blank, we are initializing the app
  if (document.title === '') {
    document.title = _(msg`Loading...`);
  }

  const agent = window.navigator.userAgent || '';
  if (agent.match(/(MSIE)/) || agent.match(/(Trident\/)/)) {
    return (
      <View sx={muiStyles.BrowserCompatibilityOuter}>
        <View sx={muiStyles.BrowserCompatibilityInner}>
          <Typography sx={muiStyles.BrowserCompatibilityText} variant="h2">
            <Trans>Your browser is not supported.</Trans>
          </Typography>
          <Typography
            mt={2}
            sx={muiStyles.BrowserCompatibilityText}
            variant="subtitle1">
            <Trans>
              This application does not support Internet Explorer. Please access
              this site with Microsoft Edge, Chrome, or Firefox.
            </Trans>
          </Typography>
        </View>
      </View>
    );
  } else {
    return <>{children}</>;
  }
};

const App = () => {
  return (
    <>
      <FeaturesProvider>
        <DebugProvider>
          {/* Global error page boundary, unthemed */}
          <ErrorBoundary fallback={<ErrorPage />}>
            <I18nCustomProvider>
              <BrowserCompatibility>
                <IsMobileAppEmbeddedProvider>
                  <FontsLoader>
                    <Store>
                      <AppProvider>
                        <ThemeProvider>
                          {/* Themed error page boundary */}
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <AuthenticationProvider>
                              <EventTrackingProvider>
                                <QueryClientProvider client={queryClient}>
                                  <MidyearUpdateProvider>
                                    <AvailableFormStepsProvider>
                                      <RootNavigation />
                                      <DebugTools />
                                    </AvailableFormStepsProvider>
                                  </MidyearUpdateProvider>
                                </QueryClientProvider>
                              </EventTrackingProvider>
                            </AuthenticationProvider>
                          </ErrorBoundary>
                        </ThemeProvider>
                      </AppProvider>
                    </Store>
                  </FontsLoader>
                </IsMobileAppEmbeddedProvider>
              </BrowserCompatibility>
            </I18nCustomProvider>
          </ErrorBoundary>
        </DebugProvider>
      </FeaturesProvider>
    </>
  );
};

export default App;
