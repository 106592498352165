import { BenefitItemType } from './benefits';
import { DataSource, PayFrequency } from './common';
import { ContributionType } from './retirement';

export enum ActionPlanStatus {
  PENDING = 'pending',
  COMPLETE = 'complete',
  EMPTY = 'empty',
}

export type HealthcareData = {
  health_benefits: HealthBenefit[];
  health_accounts: HealthAccount[];
  benadmin_url: string;
  completed_at: string | null;
};

export type RetirementData = {
  accounts: EsrpAccount[];
  retirementplan_url: string;
  completed_at: string | null;
  is_submit_enabled: boolean;
  taxable_contribution: Contribution;
};

export type EmergencyFundData = {
  contribution: Contribution;
  emergencyfund_url: string;
  completed_at: string | null;
};

export type ActionPlan = {
  id: string;
  household_id: string;
  bundles_id: string;
  selected_bundle: string;
  nextdollar_id: string;
  budget: number;
  benadmin: HealthcareData;
  esrp: RetirementData;
  esrp_spouse?: RetirementData;
  ira: any;
  emergencyfund: EmergencyFundData;
  created_at: string;
  updated_at: string;
  plan_year_label: string;
};

export type Levels = {
  subscriber: number;
  spouse?: number;
  child?: number;
};

export type Contribution = {
  type: ContributionType;
  rate: number;
  conversion_factor: number;
  frequency: PayFrequency;
};

export type HealthBenefit = {
  type: BenefitItemType;
  benefit_id: string;
  plan_id: string;
  name: string;
  person_id?: string;
  employer_id: string;
  source: DataSource;
  source_id: string;
  premium: number;
  premium_frequency: PayFrequency;
  coverage?: string;
  levels?: Levels;
};

export type HealthAccount = {
  total_contribution_amount: number;
  type: string;
  account_id: string;
  plan_id: string;
  name: string;
  person_id: string;
  employer_id: string;
  source: DataSource;
  source_id: string;
  contribution: Contribution;
  employer_contribution: Contribution;
  employer_match: Contribution;
};

type EsrpAccountStatus =
  | 'below-budget'
  | 'redistribute-budget'
  | 'on-budget'
  | 'exceeds-budget'
  | 'exceeds-target'
  | 'on-target';

export type EsrpAccount = {
  type: string;
  account_id: string;
  plan_id: string;
  plan_type: string;
  name: string;
  person_id: string;
  employer_id: string;
  source: DataSource;
  source_id: string;
  contribution_pretax: Contribution;
  contribution_pretax_catchup: Contribution;
  contribution_roth: Contribution;
  contribution_roth_catchup: Contribution;
  contribution_aftertax: Contribution;
  has_contribution_catchup: boolean;
  employer_contribution: Contribution;
  employer_match: Contribution;
  total_contribution_amount: number;
  total_contribution_percent: number;
  total_employer_amount: number;
  escalator_frequency: PayFrequency;
  escalator_increase_date: string | null;
  escalator_limit_pretax: number | null;
  escalator_limit_roth: number | null;
  escalator_step_pretax: number;
  escalator_step_roth: number;
  is_escalator_available: boolean;
  escalator_step_total_percent: number;
  escalator_limit_total_percent: number;
  is_enrolled: boolean;
  is_current_status: EsrpAccountStatus;
  periodization_factor: number;
  periodization_frequency: PayFrequency;
};
