import { msg } from '@lingui/macro';
import { PlanFrequency } from 'interfaces/benefits';
import { DataSource, Dictionary } from 'interfaces/common';

export enum ContributionType {
  PERCENT = 'percent',
  AMOUNT = 'amount',
}

export const CURRENCY_OPTIONS = [
  {
    value: 'amount',
    label: msg`Dollars`,
  },
  {
    value: 'percent',
    label: msg`Percent`,
  },
];

export type Contribution = {
  frequency: PlanFrequency;
  rate: number;
  type: ContributionType;
  amount_ytd: number;
};

export type EmployerMatchTier = {
  limit_type: any;
  rate: number;
  limit: number;
};

export const defaultEmployerMatchTier = {
  rate: 50,
  limit: 3,
};

export type RetirementAccount = {
  status: string;
  allow_decreasing: boolean;
  allow_relocation: boolean;
  balance_pretax: number;
  balance_aftertax: number;
  balance_roth: number;
  basis: number;
  basis_aftertax: number;
  basis_roth: number;
  catchup_contribution_limit: Contribution;
  contribution: Contribution;
  contribution_pretax: Contribution;
  contribution_roth: Contribution;
  contribution_aftertax: Contribution;
  is_contribution_catchup_segregated: boolean;
  contribution_pretax_catchup: Contribution;
  contribution_roth_catchup: Contribution;
  employer_contribution: Contribution;
  escalator_frequency: PlanFrequency;
  escalator_limit: number;
  escalator_step: number;
  id: string;
  is_contribution_aftertax_enabled: boolean;
  is_contribution_aftertax_update_enabled: boolean;
  is_contribution_catchup_enabled: boolean;
  is_contribution_catchup_update_enabled: boolean;
  is_contribution_enabled: boolean;
  is_contribution_roth_enabled: boolean;
  is_contribution_roth_update_enabled: boolean;
  is_contribution_update_enabled: boolean;
  is_escalator_enabled: boolean;
  name: string | null;
  person_id: string;
  regular_contribution_limit: Contribution;
  total_contribution_limit: Contribution;
  type: 'esrp';
  match_tiers: EmployerMatchTier[];
  has_match: boolean;
  has_roth: boolean;
  has_aftertax: boolean;
  plan_id: string | null;
  updated_at: string | number;
};

export type PensionAccount = {
  created_at: string;
  employer_id: string | null;
  household_id: string;
  id: string;
  monthly_payment: number;
  start_age: number | null;
  name: string | null;
  person_id: string;
  plan_id: string | null;
  source: DataSource;
  source_id: string | null;
  type: 'pension';
  updated_at: string;
};

export type IraAccount = {
  balance_pretax: number;
  balance_roth: number;
  balance?: number;
  basis: number;
  basis_roth: number;
  contribution_pretax: number;
  contribution_roth: number;
  contribution_pretax_ytd: number;
  contribution_roth_ytd: number;
  created_at?: string;
  employer_id?: null;
  household_id?: string;
  id?: string;
  name?: string | null;
  person_id?: null;
  plan_id?: null;
  source?: DataSource;
  source_id?: string | null;
  type: 'ira';
  updated_at?: string;
};

export type RetirementType = {
  retirement: Dictionary<Dictionary<RetirementAccount>>;
  pension: Dictionary<PensionAccount>;
  ira: IraAccount;
  addSpouseRetirement: boolean;
  addIraAccounts: boolean;
  initialized: boolean;
  source?: DataSource;
};

export const contributionName = (
  contribution: string,
  accountLabel: string,
) => {
  switch (contribution) {
    case 'contribution_pretax':
      return msg`Traditional (Pre-Tax) ${accountLabel} Contribution`;
    case 'contribution_pretax_catchup':
      return msg`Traditional (Pre-Tax) ${accountLabel} Catch-Up`;
    case 'contribution_roth':
      return msg`Roth (After-Tax) ${accountLabel} Contribution`;
    case 'contribution_roth_catchup':
      return msg`Roth (After-Tax) ${accountLabel} Catch-Up`;
    case 'contribution_aftertax':
      return msg`After-Tax (non-Roth) ${accountLabel} Contribution`;
    case 'taxable':
      return msg`Contribution to Taxable Savings (e.g. Brokerage)`;
    default:
      return null;
  }
};

const contribution: Contribution = {
  frequency: 'annually' as PlanFrequency,
  rate: 0,
  type: 'percent' as ContributionType,
  amount_ytd: 0,
};

export const defaultRetirementAccount = {
  balance_pretax: 0,
  balance_roth: 0,
  balance_aftertax: 0,
  contribution_pretax: contribution,
  contribution_pretax_catchup: contribution,
  contribution_roth: contribution,
  contribution_roth_catchup: contribution,
  contribution_aftertax: contribution,
  match_tiers: [],
  type: 'esrp',
};
