/**
 * Generated by @openapi-codegen
 *
 * @version DEV
 */
import * as reactQuery from '@tanstack/react-query';
import {
  useSelectsmartContext,
  SelectsmartContext,
} from './selectsmartContext';
import type * as Fetcher from './selectsmartFetcher';
import { selectsmartFetch } from './selectsmartFetcher';
import type * as Schemas from './selectsmartSchemas';
import type { ClientErrorStatus, ServerErrorStatus } from './selectsmartUtils';

export type GetInvitePathParams = {
  /**
   * InviteCode
   *
   * @minLength 3
   * @maxLength 64
   * @pattern [a-zA-Z0-9_-]+
   */
  inviteCode: string;
};

export type GetInviteError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetInviteVariables = {
  pathParams: GetInvitePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get invite information, including it's validity.
 *
 * Applications supporting invites should use this endpoint to check the invite
 * validity before offering the user the opportunity to sign-up with the invite.
 */
export const fetchGetInvite = (
  variables: GetInviteVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Invite,
    GetInviteError,
    undefined,
    {},
    {},
    GetInvitePathParams
  >({ url: '/auth/invites/{inviteCode}', method: 'get', ...variables, signal });

/**
 * Get invite information, including it's validity.
 *
 * Applications supporting invites should use this endpoint to check the invite
 * validity before offering the user the opportunity to sign-up with the invite.
 */
export const useGetInvite = <TData = Schemas.Invite>(
  variables: GetInviteVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Invite, GetInviteError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/auth/invites/{inviteCode}',
    operationId: 'getInvite',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Invite, GetInviteError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetInvite({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type RequestInviteError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type RequestInviteVariables = {
  body: Schemas.InviteRequest;
} & SelectsmartContext['fetcherOptions'];

/**
 * Request invite as part of a sign-up verification flow.
 */
export const fetchRequestInvite = (
  variables: RequestInviteVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    RequestInviteError,
    Schemas.InviteRequest,
    {},
    {},
    {}
  >({ url: '/auth/invites', method: 'post', ...variables, signal });

/**
 * Request invite as part of a sign-up verification flow.
 */
export const useRequestInvite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      RequestInviteError,
      RequestInviteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    RequestInviteError,
    RequestInviteVariables
  >({
    mutationFn: (variables: RequestInviteVariables) =>
      fetchRequestInvite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SigninError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SigninVariables = {
  body: Schemas.NewSignin;
} & SelectsmartContext['fetcherOptions'];

/**
 * Initiate sign-in for an existing user (first step).
 */
export const fetchSignin = (variables: SigninVariables, signal?: AbortSignal) =>
  selectsmartFetch<
    Schemas.NextStep,
    SigninError,
    Schemas.NewSignin,
    {},
    {},
    {}
  >({ url: '/auth/signins', method: 'post', ...variables, signal });

/**
 * Initiate sign-in for an existing user (first step).
 */
export const useSignin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NextStep,
      SigninError,
      SigninVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<Schemas.NextStep, SigninError, SigninVariables>(
    {
      mutationFn: (variables: SigninVariables) =>
        fetchSignin({ ...fetcherOptions, ...variables }),
      ...options,
    },
  );
};

export type SigninSecurityCodePathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  signinId: string;
};

export type SigninSecurityCodeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SigninSecurityCodeVariables = {
  body: Schemas.SecurityCodeChallenge;
  pathParams: SigninSecurityCodePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Verify the security code is correct and move on to the next step.
 */
export const fetchSigninSecurityCode = (
  variables: SigninSecurityCodeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.NextStep,
    SigninSecurityCodeError,
    Schemas.SecurityCodeChallenge,
    {},
    {},
    SigninSecurityCodePathParams
  >({
    url: '/auth/signins/{signinId}/security-code',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Verify the security code is correct and move on to the next step.
 */
export const useSigninSecurityCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NextStep,
      SigninSecurityCodeError,
      SigninSecurityCodeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.NextStep,
    SigninSecurityCodeError,
    SigninSecurityCodeVariables
  >({
    mutationFn: (variables: SigninSecurityCodeVariables) =>
      fetchSigninSecurityCode({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResendSecurityCodePathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  signinId: string;
};

export type ResendSecurityCodeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type ResendSecurityCodeVariables = {
  pathParams: ResendSecurityCodePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Send a new security code if the signin id is valid.
 */
export const fetchResendSecurityCode = (
  variables: ResendSecurityCodeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    undefined,
    ResendSecurityCodeError,
    undefined,
    {},
    {},
    ResendSecurityCodePathParams
  >({
    url: '/auth/signins/{signinId}/security-code',
    method: 'put',
    ...variables,
    signal,
  });

/**
 * Send a new security code if the signin id is valid.
 */
export const useResendSecurityCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ResendSecurityCodeError,
      ResendSecurityCodeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    undefined,
    ResendSecurityCodeError,
    ResendSecurityCodeVariables
  >({
    mutationFn: (variables: ResendSecurityCodeVariables) =>
      fetchResendSecurityCode({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SigninOtpPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  signinId: string;
};

export type SigninOtpError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SigninOtpVariables = {
  body: Schemas.SecurityCodeChallenge;
  pathParams: SigninOtpPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Verify the security code is correct and move on to the next step.
 */
export const fetchSigninOtp = (
  variables: SigninOtpVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.NextStep,
    SigninOtpError,
    Schemas.SecurityCodeChallenge,
    {},
    {},
    SigninOtpPathParams
  >({
    url: '/auth/signins/{signinId}/otp',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Verify the security code is correct and move on to the next step.
 */
export const useSigninOtp = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NextStep,
      SigninOtpError,
      SigninOtpVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.NextStep,
    SigninOtpError,
    SigninOtpVariables
  >({
    mutationFn: (variables: SigninOtpVariables) =>
      fetchSigninOtp({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FinalizeSigninPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  signinId: string;
};

export type FinalizeSigninQueryParams = {
  /**
   * A boolean switch/flag whose existence as a query parameter is interpreted
   * as True even if technically it is an empty string.
   */
  cookies?: boolean;
};

export type FinalizeSigninError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type FinalizeSigninVariables = {
  pathParams: FinalizeSigninPathParams;
  queryParams?: FinalizeSigninQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Finalize sign-in (last step) to get the user session.
 */
export const fetchFinalizeSignin = (
  variables: FinalizeSigninVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Session,
    FinalizeSigninError,
    undefined,
    {},
    FinalizeSigninQueryParams,
    FinalizeSigninPathParams
  >({
    url: '/auth/signins/{signinId}/finalize',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Finalize sign-in (last step) to get the user session.
 */
export const useFinalizeSignin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Session,
      FinalizeSigninError,
      FinalizeSigninVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Session,
    FinalizeSigninError,
    FinalizeSigninVariables
  >({
    mutationFn: (variables: FinalizeSigninVariables) =>
      fetchFinalizeSignin({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResetPasswordPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  signinId: string;
};

export type ResetPasswordError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type ResetPasswordVariables = {
  body: Schemas.PasswordReset;
  pathParams: ResetPasswordPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Complete authentication recovery by resetting user password.
 */
export const fetchResetPassword = (
  variables: ResetPasswordVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.NextStep,
    ResetPasswordError,
    Schemas.PasswordReset,
    {},
    {},
    ResetPasswordPathParams
  >({
    url: '/auth/signins/{signinId}/reset-password',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Complete authentication recovery by resetting user password.
 */
export const useResetPassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NextStep,
      ResetPasswordError,
      ResetPasswordVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.NextStep,
    ResetPasswordError,
    ResetPasswordVariables
  >({
    mutationFn: (variables: ResetPasswordVariables) =>
      fetchResetPassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResubscribePathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  signinId: string;
};

export type ResubscribeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type ResubscribeVariables = {
  body?: Schemas.Resubscribe;
  pathParams: ResubscribePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Resubscribe an existing user whose subscription expired
 */
export const fetchResubscribe = (
  variables: ResubscribeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.NextStep,
    ResubscribeError,
    Schemas.Resubscribe,
    {},
    {},
    ResubscribePathParams
  >({
    url: '/auth/signins/{signinId}/resubscribe',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Resubscribe an existing user whose subscription expired
 */
export const useResubscribe = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NextStep,
      ResubscribeError,
      ResubscribeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.NextStep,
    ResubscribeError,
    ResubscribeVariables
  >({
    mutationFn: (variables: ResubscribeVariables) =>
      fetchResubscribe({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SignupError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SignupVariables = {
  body: Schemas.Signup;
} & SelectsmartContext['fetcherOptions'];

/**
 * Sign-up a new user. An invite_code or payment_token is required if open sign-up not enabled.
 */
export const fetchSignup = (variables: SignupVariables, signal?: AbortSignal) =>
  selectsmartFetch<Schemas.NextStep, SignupError, Schemas.Signup, {}, {}, {}>({
    url: '/auth/signup',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Sign-up a new user. An invite_code or payment_token is required if open sign-up not enabled.
 */
export const useSignup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NextStep,
      SignupError,
      SignupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<Schemas.NextStep, SignupError, SignupVariables>(
    {
      mutationFn: (variables: SignupVariables) =>
        fetchSignup({ ...fetcherOptions, ...variables }),
      ...options,
    },
  );
};

export type GetUserError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetUserVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get current authenticated user.
 */
export const fetchGetUser = (
  variables: GetUserVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.SessionUserUpdate,
    GetUserError,
    undefined,
    {},
    {},
    {}
  >({ url: '/user', method: 'get', ...variables, signal });

/**
 * Get current authenticated user.
 */
export const useGetUser = <TData = Schemas.SessionUserUpdate>(
  variables: GetUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.SessionUserUpdate, GetUserError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/user',
    operationId: 'getUser',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.SessionUserUpdate, GetUserError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetUser({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type UpdateUserError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateUserVariables = {
  body?: Schemas.UserUpdate;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update user-updateable user details.
 */
export const fetchUpdateUser = (
  variables: UpdateUserVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.User,
    UpdateUserError,
    Schemas.UserUpdate,
    {},
    {},
    {}
  >({ url: '/user', method: 'patch', ...variables, signal });

/**
 * Update user-updateable user details.
 */
export const useUpdateUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.User,
      UpdateUserError,
      UpdateUserVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.User,
    UpdateUserError,
    UpdateUserVariables
  >({
    mutationFn: (variables: UpdateUserVariables) =>
      fetchUpdateUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdatePasswordError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdatePasswordVariables = {
  body: Schemas.PasswordChange;
} & SelectsmartContext['fetcherOptions'];

/**
 * Change user password.
 */
export const fetchUpdatePassword = (
  variables: UpdatePasswordVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    boolean,
    UpdatePasswordError,
    Schemas.PasswordChange,
    {},
    {},
    {}
  >({ url: '/user/password', method: 'post', ...variables, signal });

/**
 * Change user password.
 */
export const useUpdatePassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      boolean,
      UpdatePasswordError,
      UpdatePasswordVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    boolean,
    UpdatePasswordError,
    UpdatePasswordVariables
  >({
    mutationFn: (variables: UpdatePasswordVariables) =>
      fetchUpdatePassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SignoutQueryParams = {
  /**
   * A boolean switch/flag whose existence as a query parameter is interpreted
   * as True even if technically it is an empty string.
   */
  cookies?: boolean;
  /**
   * A boolean switch/flag whose existence as a query parameter is interpreted
   * as True even if technically it is an empty string.
   */
  auto?: boolean;
  /**
   * A boolean switch/flag whose existence as a query parameter is interpreted
   * as True even if technically it is an empty string.
   */
  nonexpiring?: boolean;
};

export type SignoutError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SignoutVariables = {
  queryParams?: SignoutQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Sign-out from user session.
 */
export const fetchSignout = (
  variables: SignoutVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    SignoutError,
    undefined,
    {},
    SignoutQueryParams,
    {}
  >({ url: '/user/session/signout', method: 'post', ...variables, signal });

/**
 * Sign-out from user session.
 */
export const useSignout = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      SignoutError,
      SignoutVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    SignoutError,
    SignoutVariables
  >({
    mutationFn: (variables: SignoutVariables) =>
      fetchSignout({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RenewSessionError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type RenewSessionVariables = SelectsmartContext['fetcherOptions'];

/**
 * Renew session before it expires.
 */
export const fetchRenewSession = (
  variables: RenewSessionVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.SessionRenewal,
    RenewSessionError,
    undefined,
    {},
    {},
    {}
  >({ url: '/user/session/renew', method: 'post', ...variables, signal });

/**
 * Renew session before it expires.
 */
export const useRenewSession = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SessionRenewal,
      RenewSessionError,
      RenewSessionVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.SessionRenewal,
    RenewSessionError,
    RenewSessionVariables
  >({
    mutationFn: (variables: RenewSessionVariables) =>
      fetchRenewSession({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetGroupPrivilegesPathParams = {
  /**
   * String
   */
  groupId: string;
};

export type GetGroupPrivilegesError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetGroupPrivilegesVariables = {
  pathParams: GetGroupPrivilegesPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get privileges that current authenticated user has for `group_id`.
 */
export const fetchGetGroupPrivileges = (
  variables: GetGroupPrivilegesVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.StringList,
    GetGroupPrivilegesError,
    undefined,
    {},
    {},
    GetGroupPrivilegesPathParams
  >({
    url: '/user/groups/{groupId}/privileges',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get privileges that current authenticated user has for `group_id`.
 */
export const useGetGroupPrivileges = <TData = Schemas.StringList>(
  variables: GetGroupPrivilegesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.StringList,
      GetGroupPrivilegesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/user/groups/{groupId}/privileges',
    operationId: 'getGroupPrivileges',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.StringList, GetGroupPrivilegesError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetGroupPrivileges({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type DeactivateError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeactivateVariables = {
  body: Schemas.Deactivation;
} & SelectsmartContext['fetcherOptions'];

/**
 * Renew session before it expires.
 */
export const fetchDeactivate = (
  variables: DeactivateVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    DeactivateError,
    Schemas.Deactivation,
    {},
    {},
    {}
  >({ url: '/user/deactivate', method: 'post', ...variables, signal });

/**
 * Renew session before it expires.
 */
export const useDeactivate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      DeactivateError,
      DeactivateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    DeactivateError,
    DeactivateVariables
  >({
    mutationFn: (variables: DeactivateVariables) =>
      fetchDeactivate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RequestChangeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type RequestChangeVariables = {
  body: Schemas.ChangeRequest;
} & SelectsmartContext['fetcherOptions'];

/**
 * Take an email address or phone number and return a confirmation_id.
 */
export const fetchRequestChange = (
  variables: RequestChangeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    string,
    RequestChangeError,
    Schemas.ChangeRequest,
    {},
    {},
    {}
  >({ url: '/user/changes', method: 'post', ...variables, signal });

/**
 * Take an email address or phone number and return a confirmation_id.
 */
export const useRequestChange = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      RequestChangeError,
      RequestChangeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    string,
    RequestChangeError,
    RequestChangeVariables
  >({
    mutationFn: (variables: RequestChangeVariables) =>
      fetchRequestChange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ConfirmChangePathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  confirmationId: string;
};

export type ConfirmChangeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type ConfirmChangeVariables = {
  body: Schemas.ChangeConfirmation;
  pathParams: ConfirmChangePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Verify the confirmation code is correct
 */
export const fetchConfirmChange = (
  variables: ConfirmChangeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.SessionUserUpdate,
    ConfirmChangeError,
    Schemas.ChangeConfirmation,
    {},
    {},
    ConfirmChangePathParams
  >({
    url: '/user/changes/{confirmationId}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Verify the confirmation code is correct
 */
export const useConfirmChange = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SessionUserUpdate,
      ConfirmChangeError,
      ConfirmChangeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.SessionUserUpdate,
    ConfirmChangeError,
    ConfirmChangeVariables
  >({
    mutationFn: (variables: ConfirmChangeVariables) =>
      fetchConfirmChange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EnableMfaSmsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type EnableMfaSmsVariables = {
  body?: Schemas.MFASetupSMS;
} & SelectsmartContext['fetcherOptions'];

/**
 * Initiate enabling SMS-based MFA by sending a confirmation code to the user’s phone and returning the next step
 */
export const fetchEnableMfaSms = (
  variables: EnableMfaSmsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.NextStep,
    EnableMfaSmsError,
    Schemas.MFASetupSMS,
    {},
    {},
    {}
  >({ url: '/user/mfa/sms/enable', method: 'post', ...variables, signal });

/**
 * Initiate enabling SMS-based MFA by sending a confirmation code to the user’s phone and returning the next step
 */
export const useEnableMfaSms = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NextStep,
      EnableMfaSmsError,
      EnableMfaSmsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.NextStep,
    EnableMfaSmsError,
    EnableMfaSmsVariables
  >({
    mutationFn: (variables: EnableMfaSmsVariables) =>
      fetchEnableMfaSms({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ConfirmEnableMfaSmsPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  confirmationId: string;
};

export type ConfirmEnableMfaSmsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type ConfirmEnableMfaSmsVariables = {
  body: Schemas.ChangeConfirmation;
  pathParams: ConfirmEnableMfaSmsPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Enable MFA if the confirmation code is correct and return the backup codes
 */
export const fetchConfirmEnableMfaSms = (
  variables: ConfirmEnableMfaSmsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.SecurityOrBackupCodeList,
    ConfirmEnableMfaSmsError,
    Schemas.ChangeConfirmation,
    {},
    {},
    ConfirmEnableMfaSmsPathParams
  >({
    url: '/user/mfa/sms/enable/{confirmationId}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Enable MFA if the confirmation code is correct and return the backup codes
 */
export const useConfirmEnableMfaSms = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SecurityOrBackupCodeList,
      ConfirmEnableMfaSmsError,
      ConfirmEnableMfaSmsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.SecurityOrBackupCodeList,
    ConfirmEnableMfaSmsError,
    ConfirmEnableMfaSmsVariables
  >({
    mutationFn: (variables: ConfirmEnableMfaSmsVariables) =>
      fetchConfirmEnableMfaSms({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DisableMfaSmsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DisableMfaSmsVariables = SelectsmartContext['fetcherOptions'];

/**
 * Initiate disabling SMS-based MFA by sending a confirmation code to the user’s phone and returning the next step
 */
export const fetchDisableMfaSms = (
  variables: DisableMfaSmsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.NextStep, DisableMfaSmsError, undefined, {}, {}, {}>(
    { url: '/user/mfa/sms/disable', method: 'post', ...variables, signal },
  );

/**
 * Initiate disabling SMS-based MFA by sending a confirmation code to the user’s phone and returning the next step
 */
export const useDisableMfaSms = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NextStep,
      DisableMfaSmsError,
      DisableMfaSmsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.NextStep,
    DisableMfaSmsError,
    DisableMfaSmsVariables
  >({
    mutationFn: (variables: DisableMfaSmsVariables) =>
      fetchDisableMfaSms({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ConfirmDisableMfaSmsPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  confirmationId: string;
};

export type ConfirmDisableMfaSmsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type ConfirmDisableMfaSmsVariables = {
  body: Schemas.SecurityCodeChallenge;
  pathParams: ConfirmDisableMfaSmsPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Disable MFA if the security code is correct and return True if it was disabled
 */
export const fetchConfirmDisableMfaSms = (
  variables: ConfirmDisableMfaSmsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    ConfirmDisableMfaSmsError,
    Schemas.SecurityCodeChallenge,
    {},
    {},
    ConfirmDisableMfaSmsPathParams
  >({
    url: '/user/mfa/sms/disable/{confirmationId}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Disable MFA if the security code is correct and return True if it was disabled
 */
export const useConfirmDisableMfaSms = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      ConfirmDisableMfaSmsError,
      ConfirmDisableMfaSmsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    ConfirmDisableMfaSmsError,
    ConfirmDisableMfaSmsVariables
  >({
    mutationFn: (variables: ConfirmDisableMfaSmsVariables) =>
      fetchConfirmDisableMfaSms({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EnableMfaOtpError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type EnableMfaOtpVariables = {
  body?: Schemas.MFASetupOTP;
} & SelectsmartContext['fetcherOptions'];

/**
 * Initiate enabling OTP-based MFA by generating an otp secret and returning the next step
 */
export const fetchEnableMfaOtp = (
  variables: EnableMfaOtpVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.NextStepOTP,
    EnableMfaOtpError,
    Schemas.MFASetupOTP,
    {},
    {},
    {}
  >({ url: '/user/mfa/otp/enable', method: 'post', ...variables, signal });

/**
 * Initiate enabling OTP-based MFA by generating an otp secret and returning the next step
 */
export const useEnableMfaOtp = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NextStepOTP,
      EnableMfaOtpError,
      EnableMfaOtpVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.NextStepOTP,
    EnableMfaOtpError,
    EnableMfaOtpVariables
  >({
    mutationFn: (variables: EnableMfaOtpVariables) =>
      fetchEnableMfaOtp({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ConfirmEnableMfaOtpPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  confirmationId: string;
};

export type ConfirmEnableMfaOtpError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type ConfirmEnableMfaOtpVariables = {
  body: Schemas.SecurityCodeChallenge;
  pathParams: ConfirmEnableMfaOtpPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Enable MFA if the security code is correct and return the backup codes
 */
export const fetchConfirmEnableMfaOtp = (
  variables: ConfirmEnableMfaOtpVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.SecurityOrBackupCodeList,
    ConfirmEnableMfaOtpError,
    Schemas.SecurityCodeChallenge,
    {},
    {},
    ConfirmEnableMfaOtpPathParams
  >({
    url: '/user/mfa/otp/enable/{confirmationId}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Enable MFA if the security code is correct and return the backup codes
 */
export const useConfirmEnableMfaOtp = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SecurityOrBackupCodeList,
      ConfirmEnableMfaOtpError,
      ConfirmEnableMfaOtpVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.SecurityOrBackupCodeList,
    ConfirmEnableMfaOtpError,
    ConfirmEnableMfaOtpVariables
  >({
    mutationFn: (variables: ConfirmEnableMfaOtpVariables) =>
      fetchConfirmEnableMfaOtp({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DisableMfaOtpError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DisableMfaOtpVariables = SelectsmartContext['fetcherOptions'];

/**
 * Initiate disabling OTP-based MFA by generating a confirmation id and returning the next step
 */
export const fetchDisableMfaOtp = (
  variables: DisableMfaOtpVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.NextStep, DisableMfaOtpError, undefined, {}, {}, {}>(
    { url: '/user/mfa/otp/disable', method: 'post', ...variables, signal },
  );

/**
 * Initiate disabling OTP-based MFA by generating a confirmation id and returning the next step
 */
export const useDisableMfaOtp = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NextStep,
      DisableMfaOtpError,
      DisableMfaOtpVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.NextStep,
    DisableMfaOtpError,
    DisableMfaOtpVariables
  >({
    mutationFn: (variables: DisableMfaOtpVariables) =>
      fetchDisableMfaOtp({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ConfirmDisableMfaOtpPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  confirmationId: string;
};

export type ConfirmDisableMfaOtpError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type ConfirmDisableMfaOtpVariables = {
  body: Schemas.SecurityCodeChallenge;
  pathParams: ConfirmDisableMfaOtpPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Disable MFA if the security code is correct and return True if it was disabled
 */
export const fetchConfirmDisableMfaOtp = (
  variables: ConfirmDisableMfaOtpVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    ConfirmDisableMfaOtpError,
    Schemas.SecurityCodeChallenge,
    {},
    {},
    ConfirmDisableMfaOtpPathParams
  >({
    url: '/user/mfa/otp/disable/{confirmationId}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Disable MFA if the security code is correct and return True if it was disabled
 */
export const useConfirmDisableMfaOtp = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      ConfirmDisableMfaOtpError,
      ConfirmDisableMfaOtpVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    ConfirmDisableMfaOtpError,
    ConfirmDisableMfaOtpVariables
  >({
    mutationFn: (variables: ConfirmDisableMfaOtpVariables) =>
      fetchConfirmDisableMfaOtp({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateAccessTokenError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type CreateAccessTokenVariables = {
  body: Schemas.OAuth2TokenRequest;
} & SelectsmartContext['fetcherOptions'];

/**
 * Authenticate end-user with client_id, client_secret and username using the OAuth2
 * Client Credentials (machine-to-machine) flow with username added to the request
 * in order to obtain an access/session token for the identified user.
 *
 * Here's an example of a POST request
 *
 * ```json
 * {
 * "client_id": "0ff5bab7-1370-47c2-8d13-256793232d59",
 * "client_secret": "a secret kept by a friend",
 * "username": "unique-user-id"
 * }
 * ```
 *
 * where `username` must be a permanent unique identifier for the user established
 * by the caller. If the `username` hasn't been previously authenticated, a new user
 * will be created.
 *
 * The response returned:
 *
 * ```json
 * {
 * "access_token": "efa9f9eb94724fe39f35110b09e60f8c.4b4b42a8c4fff7a65180298d82db1b2fdfbfc3abc3bb5ede8e4b90a487ba26de",
 * "token_type": "Bearer",
 * "expires_in": 86399
 * }
 * ```
 *
 * The `access_token` would be used as an Authorization Bearer token in future request
 * headers like:
 *
 * ```
 * Authorization: Bearer efa9f9eb94724fe39f35110b09e60f8c.4b4b42a8c4fff7a65180298d82db1b2fdfbfc3abc3bb5ede8e4b90a487ba26de
 * ```
 *
 * Expiration is returned by `expires_in` is in seconds. Lifetime is configurable per
 * client.
 */
export const fetchCreateAccessToken = (
  variables: CreateAccessTokenVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OAuth2Token,
    CreateAccessTokenError,
    Schemas.OAuth2TokenRequest,
    {},
    {},
    {}
  >({ url: '/auth/oauth2/token', method: 'post', ...variables, signal });

/**
 * Authenticate end-user with client_id, client_secret and username using the OAuth2
 * Client Credentials (machine-to-machine) flow with username added to the request
 * in order to obtain an access/session token for the identified user.
 *
 * Here's an example of a POST request
 *
 * ```json
 * {
 * "client_id": "0ff5bab7-1370-47c2-8d13-256793232d59",
 * "client_secret": "a secret kept by a friend",
 * "username": "unique-user-id"
 * }
 * ```
 *
 * where `username` must be a permanent unique identifier for the user established
 * by the caller. If the `username` hasn't been previously authenticated, a new user
 * will be created.
 *
 * The response returned:
 *
 * ```json
 * {
 * "access_token": "efa9f9eb94724fe39f35110b09e60f8c.4b4b42a8c4fff7a65180298d82db1b2fdfbfc3abc3bb5ede8e4b90a487ba26de",
 * "token_type": "Bearer",
 * "expires_in": 86399
 * }
 * ```
 *
 * The `access_token` would be used as an Authorization Bearer token in future request
 * headers like:
 *
 * ```
 * Authorization: Bearer efa9f9eb94724fe39f35110b09e60f8c.4b4b42a8c4fff7a65180298d82db1b2fdfbfc3abc3bb5ede8e4b90a487ba26de
 * ```
 *
 * Expiration is returned by `expires_in` is in seconds. Lifetime is configurable per
 * client.
 */
export const useCreateAccessToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OAuth2Token,
      CreateAccessTokenError,
      CreateAccessTokenVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OAuth2Token,
    CreateAccessTokenError,
    CreateAccessTokenVariables
  >({
    mutationFn: (variables: CreateAccessTokenVariables) =>
      fetchCreateAccessToken({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateMilestonesError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type CreateMilestonesVariables = {
  body?: Schemas.NewGuidanceMilestoneGuidanceMilestones;
} & SelectsmartContext['fetcherOptions'];

export const fetchCreateMilestones = (
  variables: CreateMilestonesVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.GuidanceMilestones,
    CreateMilestonesError,
    Schemas.NewGuidanceMilestoneGuidanceMilestones,
    {},
    {},
    {}
  >({ url: '/selectsmart/milestones', method: 'post', ...variables, signal });

export const useCreateMilestones = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GuidanceMilestones,
      CreateMilestonesError,
      CreateMilestonesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.GuidanceMilestones,
    CreateMilestonesError,
    CreateMilestonesVariables
  >({
    mutationFn: (variables: CreateMilestonesVariables) =>
      fetchCreateMilestones({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetEmployerBenefitsYearPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  employerId: string;
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  planYear: string;
};

export type GetEmployerBenefitsYearQueryParams = {
  /**
   * A boolean switch/flag whose existence as a query parameter is interpreted
   * as True even if technically it is an empty string.
   */
  strict?: boolean;
};

export type GetEmployerBenefitsYearError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetEmployerBenefitsYearVariables = {
  pathParams: GetEmployerBenefitsYearPathParams;
  queryParams?: GetEmployerBenefitsYearQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get employer benefits enrollment information for a specific plan year.
 */
export const fetchGetEmployerBenefitsYear = (
  variables: GetEmployerBenefitsYearVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.EmployerBenefitsYear,
    GetEmployerBenefitsYearError,
    undefined,
    {},
    GetEmployerBenefitsYearQueryParams,
    GetEmployerBenefitsYearPathParams
  >({
    url: '/selectsmart/employers/{employerId}/{planYear}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get employer benefits enrollment information for a specific plan year.
 */
export const useGetEmployerBenefitsYear = <
  TData = Schemas.EmployerBenefitsYear,
>(
  variables: GetEmployerBenefitsYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.EmployerBenefitsYear,
      GetEmployerBenefitsYearError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/employers/{employerId}/{planYear}',
    operationId: 'getEmployerBenefitsYear',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<
      Schemas.EmployerBenefitsYear,
      GetEmployerBenefitsYearError,
      TData
    >({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetEmployerBenefitsYear(
          { ...fetcherOptions, ...variables },
          signal,
        ),
      ...options,
      ...queryOptions,
    }),
  };
};

export type GetEmployerPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  employerId: string;
};

export type GetEmployerQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
  /**
   * A boolean switch/flag whose existence as a query parameter is interpreted
   * as True even if technically it is an empty string.
   */
  strict?: boolean;
};

export type GetEmployerError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetEmployerVariables = {
  pathParams: GetEmployerPathParams;
  queryParams?: GetEmployerQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get employer benefits enrollment information for the next available plan year
 * (by default) or specified year (using query parameter).
 */
export const fetchGetEmployer = (
  variables: GetEmployerVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.EmployerBenefitsYear,
    GetEmployerError,
    undefined,
    {},
    GetEmployerQueryParams,
    GetEmployerPathParams
  >({
    url: '/selectsmart/employers/{employerId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get employer benefits enrollment information for the next available plan year
 * (by default) or specified year (using query parameter).
 */
export const useGetEmployer = <TData = Schemas.EmployerBenefitsYear>(
  variables: GetEmployerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.EmployerBenefitsYear,
      GetEmployerError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/employers/{employerId}',
    operationId: 'getEmployer',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<
      Schemas.EmployerBenefitsYear,
      GetEmployerError,
      TData
    >({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetEmployer({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type GetBenefitsYearQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
  /**
   * A boolean switch/flag whose existence as a query parameter is interpreted
   * as True even if technically it is an empty string.
   */
  strict?: boolean;
};

export type GetBenefitsYearError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetBenefitsYearVariables = {
  queryParams?: GetBenefitsYearQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get employer benefits enrollment information for the next available plan year
 * (by default) or specified year (using query parameter).
 */
export const fetchGetBenefitsYear = (
  variables: GetBenefitsYearVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.EmployerBenefitsYear,
    GetBenefitsYearError,
    undefined,
    {},
    GetBenefitsYearQueryParams,
    {}
  >({ url: '/selectsmart/employer', method: 'get', ...variables, signal });

/**
 * Get employer benefits enrollment information for the next available plan year
 * (by default) or specified year (using query parameter).
 */
export const useGetBenefitsYear = <TData = Schemas.EmployerBenefitsYear>(
  variables: GetBenefitsYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.EmployerBenefitsYear,
      GetBenefitsYearError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/employer',
    operationId: 'getBenefitsYear',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<
      Schemas.EmployerBenefitsYear,
      GetBenefitsYearError,
      TData
    >({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetBenefitsYear({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type CreatePersonError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type CreatePersonVariables = {
  body?: Schemas.NewPerson;
} & SelectsmartContext['fetcherOptions'];

/**
 * Create a person in the household.
 */
export const fetchCreatePerson = (
  variables: CreatePersonVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Person,
    CreatePersonError,
    Schemas.NewPerson,
    {},
    {},
    {}
  >({ url: '/selectsmart/people', method: 'post', ...variables, signal });

/**
 * Create a person in the household.
 */
export const useCreatePerson = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Person,
      CreatePersonError,
      CreatePersonVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Person,
    CreatePersonError,
    CreatePersonVariables
  >({
    mutationFn: (variables: CreatePersonVariables) =>
      fetchCreatePerson({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPeopleError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetPeopleVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get household people.
 */
export const fetchGetPeople = (
  variables: GetPeopleVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.PersonMap, GetPeopleError, undefined, {}, {}, {}>({
    url: '/selectsmart/people',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get household people.
 */
export const useGetPeople = <TData = Schemas.PersonMap>(
  variables: GetPeopleVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PersonMap, GetPeopleError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/people',
    operationId: 'getPeople',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.PersonMap, GetPeopleError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetPeople({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type UpdatePersonPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type UpdatePersonError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdatePersonVariables = {
  body?: Schemas.PersonUpdate;
  pathParams: UpdatePersonPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update a person in a household.
 */
export const fetchUpdatePerson = (
  variables: UpdatePersonVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Person,
    UpdatePersonError,
    Schemas.PersonUpdate,
    {},
    {},
    UpdatePersonPathParams
  >({
    url: '/selectsmart/people/{personId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update a person in a household.
 */
export const useUpdatePerson = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Person,
      UpdatePersonError,
      UpdatePersonVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Person,
    UpdatePersonError,
    UpdatePersonVariables
  >({
    mutationFn: (variables: UpdatePersonVariables) =>
      fetchUpdatePerson({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPersonPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type GetPersonError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetPersonVariables = {
  pathParams: GetPersonPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get a person in a household.
 */
export const fetchGetPerson = (
  variables: GetPersonVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Person,
    GetPersonError,
    undefined,
    {},
    {},
    GetPersonPathParams
  >({
    url: '/selectsmart/people/{personId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a person in a household.
 */
export const useGetPerson = <TData = Schemas.Person>(
  variables: GetPersonVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Person, GetPersonError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/people/{personId}',
    operationId: 'getPerson',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Person, GetPersonError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetPerson({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type DeletePersonPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type DeletePersonError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeletePersonVariables = {
  pathParams: DeletePersonPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Delete a person in a household.
 */
export const fetchDeletePerson = (
  variables: DeletePersonVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    undefined,
    DeletePersonError,
    undefined,
    {},
    {},
    DeletePersonPathParams
  >({
    url: '/selectsmart/people/{personId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete a person in a household.
 */
export const useDeletePerson = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePersonError,
      DeletePersonVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    undefined,
    DeletePersonError,
    DeletePersonVariables
  >({
    mutationFn: (variables: DeletePersonVariables) =>
      fetchDeletePerson({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateHouseholdError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateHouseholdVariables = {
  body?: Schemas.HouseholdUpdate;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update household data.
 */
export const fetchUpdateHousehold = (
  variables: UpdateHouseholdVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Household,
    UpdateHouseholdError,
    Schemas.HouseholdUpdate,
    {},
    {},
    {}
  >({ url: '/selectsmart/household', method: 'patch', ...variables, signal });

/**
 * Update household data.
 */
export const useUpdateHousehold = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Household,
      UpdateHouseholdError,
      UpdateHouseholdVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Household,
    UpdateHouseholdError,
    UpdateHouseholdVariables
  >({
    mutationFn: (variables: UpdateHouseholdVariables) =>
      fetchUpdateHousehold({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetHouseholdError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetHouseholdVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get household data.
 */
export const fetchGetHousehold = (
  variables: GetHouseholdVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.Household, GetHouseholdError, undefined, {}, {}, {}>(
    { url: '/selectsmart/household', method: 'get', ...variables, signal },
  );

/**
 * Get household data.
 */
export const useGetHousehold = <TData = Schemas.Household>(
  variables: GetHouseholdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Household, GetHouseholdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/household',
    operationId: 'getHousehold',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Household, GetHouseholdError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetHousehold({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type GetHouseholdIncomeLevelsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetHouseholdIncomeLevelsVariables =
  SelectsmartContext['fetcherOptions'];

/**
 * Get household income levels data.
 */
export const fetchGetHouseholdIncomeLevels = (
  variables: GetHouseholdIncomeLevelsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.HouseholdIncomeLevels,
    GetHouseholdIncomeLevelsError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/selectsmart/household/income-levels',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get household income levels data.
 */
export const useGetHouseholdIncomeLevels = <
  TData = Schemas.HouseholdIncomeLevels,
>(
  variables: GetHouseholdIncomeLevelsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.HouseholdIncomeLevels,
      GetHouseholdIncomeLevelsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/household/income-levels',
    operationId: 'getHouseholdIncomeLevels',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<
      Schemas.HouseholdIncomeLevels,
      GetHouseholdIncomeLevelsError,
      TData
    >({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetHouseholdIncomeLevels(
          { ...fetcherOptions, ...variables },
          signal,
        ),
      ...options,
      ...queryOptions,
    }),
  };
};

export type SetIncomePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type SetIncomeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SetIncomeVariables = {
  body?: Schemas.IncomeUpdate;
  pathParams: SetIncomePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Create or replace income data for a person in a household.
 */
export const fetchSetIncome = (
  variables: SetIncomeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Income,
    SetIncomeError,
    Schemas.IncomeUpdate,
    {},
    {},
    SetIncomePathParams
  >({
    url: '/selectsmart/incomes/{personId}',
    method: 'put',
    ...variables,
    signal,
  });

/**
 * Create or replace income data for a person in a household.
 */
export const useSetIncome = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Income,
      SetIncomeError,
      SetIncomeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Income,
    SetIncomeError,
    SetIncomeVariables
  >({
    mutationFn: (variables: SetIncomeVariables) =>
      fetchSetIncome({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateIncomePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type UpdateIncomeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateIncomeVariables = {
  body?: Schemas.IncomeUpdate;
  pathParams: UpdateIncomePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update a income in a household.
 */
export const fetchUpdateIncome = (
  variables: UpdateIncomeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Income,
    UpdateIncomeError,
    Schemas.IncomeUpdate,
    {},
    {},
    UpdateIncomePathParams
  >({
    url: '/selectsmart/incomes/{personId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update a income in a household.
 */
export const useUpdateIncome = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Income,
      UpdateIncomeError,
      UpdateIncomeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Income,
    UpdateIncomeError,
    UpdateIncomeVariables
  >({
    mutationFn: (variables: UpdateIncomeVariables) =>
      fetchUpdateIncome({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetIncomePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type GetIncomeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetIncomeVariables = {
  pathParams: GetIncomePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get a income in a household.
 */
export const fetchGetIncome = (
  variables: GetIncomeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Income,
    GetIncomeError,
    undefined,
    {},
    {},
    GetIncomePathParams
  >({
    url: '/selectsmart/incomes/{personId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a income in a household.
 */
export const useGetIncome = <TData = Schemas.Income>(
  variables: GetIncomeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Income, GetIncomeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/incomes/{personId}',
    operationId: 'getIncome',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Income, GetIncomeError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetIncome({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type DeleteIncomePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type DeleteIncomeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeleteIncomeVariables = {
  pathParams: DeleteIncomePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Delete a income in a household.
 */
export const fetchDeleteIncome = (
  variables: DeleteIncomeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    undefined,
    DeleteIncomeError,
    undefined,
    {},
    {},
    DeleteIncomePathParams
  >({
    url: '/selectsmart/incomes/{personId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete a income in a household.
 */
export const useDeleteIncome = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteIncomeError,
      DeleteIncomeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    undefined,
    DeleteIncomeError,
    DeleteIncomeVariables
  >({
    mutationFn: (variables: DeleteIncomeVariables) =>
      fetchDeleteIncome({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetIncomesError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetIncomesVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get household incomes.
 */
export const fetchGetIncomes = (
  variables: GetIncomesVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.IncomeMap, GetIncomesError, undefined, {}, {}, {}>({
    url: '/selectsmart/incomes',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get household incomes.
 */
export const useGetIncomes = <TData = Schemas.IncomeMap>(
  variables: GetIncomesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.IncomeMap, GetIncomesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/incomes',
    operationId: 'getIncomes',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.IncomeMap, GetIncomesError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetIncomes({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type SetTobaccoUsePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type SetTobaccoUseError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SetTobaccoUseVariables = {
  body?: Schemas.TobaccoUseUpdate;
  pathParams: SetTobaccoUsePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Replace an tobacco use for a person in a household.
 */
export const fetchSetTobaccoUse = (
  variables: SetTobaccoUseVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.TobaccoUse,
    SetTobaccoUseError,
    Schemas.TobaccoUseUpdate,
    {},
    {},
    SetTobaccoUsePathParams
  >({
    url: '/selectsmart/tobacco-use/{personId}',
    method: 'put',
    ...variables,
    signal,
  });

/**
 * Replace an tobacco use for a person in a household.
 */
export const useSetTobaccoUse = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TobaccoUse,
      SetTobaccoUseError,
      SetTobaccoUseVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.TobaccoUse,
    SetTobaccoUseError,
    SetTobaccoUseVariables
  >({
    mutationFn: (variables: SetTobaccoUseVariables) =>
      fetchSetTobaccoUse({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateTobaccoUsePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type UpdateTobaccoUseError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateTobaccoUseVariables = {
  body?: Schemas.TobaccoUseUpdate;
  pathParams: UpdateTobaccoUsePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update tobacco use for a person in a household.
 */
export const fetchUpdateTobaccoUse = (
  variables: UpdateTobaccoUseVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.TobaccoUse,
    UpdateTobaccoUseError,
    Schemas.TobaccoUseUpdate,
    {},
    {},
    UpdateTobaccoUsePathParams
  >({
    url: '/selectsmart/tobacco-use/{personId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update tobacco use for a person in a household.
 */
export const useUpdateTobaccoUse = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TobaccoUse,
      UpdateTobaccoUseError,
      UpdateTobaccoUseVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.TobaccoUse,
    UpdateTobaccoUseError,
    UpdateTobaccoUseVariables
  >({
    mutationFn: (variables: UpdateTobaccoUseVariables) =>
      fetchUpdateTobaccoUse({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTobaccoUsePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type GetTobaccoUseError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetTobaccoUseVariables = {
  pathParams: GetTobaccoUsePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get a tobacco use for a person in a household.
 */
export const fetchGetTobaccoUse = (
  variables: GetTobaccoUseVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.TobaccoUse,
    GetTobaccoUseError,
    undefined,
    {},
    {},
    GetTobaccoUsePathParams
  >({
    url: '/selectsmart/tobacco-use/{personId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a tobacco use for a person in a household.
 */
export const useGetTobaccoUse = <TData = Schemas.TobaccoUse>(
  variables: GetTobaccoUseVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TobaccoUse, GetTobaccoUseError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/tobacco-use/{personId}',
    operationId: 'getTobaccoUse',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.TobaccoUse, GetTobaccoUseError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetTobaccoUse({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type DeleteTobaccoUsePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type DeleteTobaccoUseError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeleteTobaccoUseVariables = {
  pathParams: DeleteTobaccoUsePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Delete a tobacco use for a person in a household.
 */
export const fetchDeleteTobaccoUse = (
  variables: DeleteTobaccoUseVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    undefined,
    DeleteTobaccoUseError,
    undefined,
    {},
    {},
    DeleteTobaccoUsePathParams
  >({
    url: '/selectsmart/tobacco-use/{personId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete a tobacco use for a person in a household.
 */
export const useDeleteTobaccoUse = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteTobaccoUseError,
      DeleteTobaccoUseVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    undefined,
    DeleteTobaccoUseError,
    DeleteTobaccoUseVariables
  >({
    mutationFn: (variables: DeleteTobaccoUseVariables) =>
      fetchDeleteTobaccoUse({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTobaccoUsesError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetTobaccoUsesVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get household tobacco use.
 */
export const fetchGetTobaccoUses = (
  variables: GetTobaccoUsesVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.TobaccoUseMap,
    GetTobaccoUsesError,
    undefined,
    {},
    {},
    {}
  >({ url: '/selectsmart/tobacco-use', method: 'get', ...variables, signal });

/**
 * Get household tobacco use.
 */
export const useGetTobaccoUses = <TData = Schemas.TobaccoUseMap>(
  variables: GetTobaccoUsesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TobaccoUseMap,
      GetTobaccoUsesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/tobacco-use',
    operationId: 'getTobaccoUses',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.TobaccoUseMap, GetTobaccoUsesError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetTobaccoUses({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type SetHealthcarePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type SetHealthcareError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SetHealthcareVariables = {
  body?: Schemas.NewHealthcare;
  pathParams: SetHealthcarePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Replace or create healthcare utilization for a person in the household.
 */
export const fetchSetHealthcare = (
  variables: SetHealthcareVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Healthcare,
    SetHealthcareError,
    Schemas.NewHealthcare,
    {},
    {},
    SetHealthcarePathParams
  >({
    url: '/selectsmart/healthcare/{personId}',
    method: 'put',
    ...variables,
    signal,
  });

/**
 * Replace or create healthcare utilization for a person in the household.
 */
export const useSetHealthcare = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Healthcare,
      SetHealthcareError,
      SetHealthcareVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Healthcare,
    SetHealthcareError,
    SetHealthcareVariables
  >({
    mutationFn: (variables: SetHealthcareVariables) =>
      fetchSetHealthcare({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateHealthcarePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type UpdateHealthcareError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateHealthcareVariables = {
  body?: Schemas.HealthcareUpdate;
  pathParams: UpdateHealthcarePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update healthcare utilization for a person in a household.
 */
export const fetchUpdateHealthcare = (
  variables: UpdateHealthcareVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Healthcare,
    UpdateHealthcareError,
    Schemas.HealthcareUpdate,
    {},
    {},
    UpdateHealthcarePathParams
  >({
    url: '/selectsmart/healthcare/{personId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update healthcare utilization for a person in a household.
 */
export const useUpdateHealthcare = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Healthcare,
      UpdateHealthcareError,
      UpdateHealthcareVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Healthcare,
    UpdateHealthcareError,
    UpdateHealthcareVariables
  >({
    mutationFn: (variables: UpdateHealthcareVariables) =>
      fetchUpdateHealthcare({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetHealthcarePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type GetHealthcareError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetHealthcareVariables = {
  pathParams: GetHealthcarePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get a healthcare utilization for a person in a household.
 */
export const fetchGetHealthcare = (
  variables: GetHealthcareVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Healthcare,
    GetHealthcareError,
    undefined,
    {},
    {},
    GetHealthcarePathParams
  >({
    url: '/selectsmart/healthcare/{personId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a healthcare utilization for a person in a household.
 */
export const useGetHealthcare = <TData = Schemas.Healthcare>(
  variables: GetHealthcareVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Healthcare, GetHealthcareError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/healthcare/{personId}',
    operationId: 'getHealthcare',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Healthcare, GetHealthcareError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetHealthcare({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type DeleteHealthcarePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  personId: string;
};

export type DeleteHealthcareError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeleteHealthcareVariables = {
  pathParams: DeleteHealthcarePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Delete healthcare utilization for a person in a household.
 */
export const fetchDeleteHealthcare = (
  variables: DeleteHealthcareVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    undefined,
    DeleteHealthcareError,
    undefined,
    {},
    {},
    DeleteHealthcarePathParams
  >({
    url: '/selectsmart/healthcare/{personId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete healthcare utilization for a person in a household.
 */
export const useDeleteHealthcare = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteHealthcareError,
      DeleteHealthcareVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    undefined,
    DeleteHealthcareError,
    DeleteHealthcareVariables
  >({
    mutationFn: (variables: DeleteHealthcareVariables) =>
      fetchDeleteHealthcare({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetHealthcaresError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetHealthcaresVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get household healthcare utilization.
 */
export const fetchGetHealthcares = (
  variables: GetHealthcaresVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.HealthcareMap,
    GetHealthcaresError,
    undefined,
    {},
    {},
    {}
  >({ url: '/selectsmart/healthcare', method: 'get', ...variables, signal });

/**
 * Get household healthcare utilization.
 */
export const useGetHealthcares = <TData = Schemas.HealthcareMap>(
  variables: GetHealthcaresVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.HealthcareMap,
      GetHealthcaresError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/healthcare',
    operationId: 'getHealthcares',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.HealthcareMap, GetHealthcaresError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetHealthcares({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type GetUsageLevelAssumptionsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetUsageLevelAssumptionsVariables =
  SelectsmartContext['fetcherOptions'];

/**
 * Get data for usage level assumptions.
 */
export const fetchGetUsageLevelAssumptions = (
  variables: GetUsageLevelAssumptionsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Map,
    GetUsageLevelAssumptionsError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/selectsmart/assumptions/usage-levels',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get data for usage level assumptions.
 */
export const useGetUsageLevelAssumptions = <TData = Schemas.Map>(
  variables: GetUsageLevelAssumptionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Map,
      GetUsageLevelAssumptionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/assumptions/usage-levels',
    operationId: 'getUsageLevelAssumptions',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Map, GetUsageLevelAssumptionsError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetUsageLevelAssumptions(
          { ...fetcherOptions, ...variables },
          signal,
        ),
      ...options,
      ...queryOptions,
    }),
  };
};

export type GetTypicalServicesError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetTypicalServicesVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get full-year special service counts by person for typical usage levels.
 */
export const fetchGetTypicalServices = (
  variables: GetTypicalServicesVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.HealthcareTypicalSpecialMap,
    GetTypicalServicesError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/selectsmart/assumptions/typical-services',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get full-year special service counts by person for typical usage levels.
 */
export const useGetTypicalServices = <
  TData = Schemas.HealthcareTypicalSpecialMap,
>(
  variables: GetTypicalServicesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.HealthcareTypicalSpecialMap,
      GetTypicalServicesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/assumptions/typical-services',
    operationId: 'getTypicalServices',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<
      Schemas.HealthcareTypicalSpecialMap,
      GetTypicalServicesError,
      TData
    >({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetTypicalServices({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type CreatePlanError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type CreatePlanVariables = {
  body: Schemas.NewPlan;
} & SelectsmartContext['fetcherOptions'];

/**
 * Create a plan in the household.
 *
 * Translations are not suported by this endpoint. Any text submitted will be the text
 * for all locales.
 */
export const fetchCreatePlan = (
  variables: CreatePlanVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.Plan, CreatePlanError, Schemas.NewPlan, {}, {}, {}>({
    url: '/selectsmart/plans',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Create a plan in the household.
 *
 * Translations are not suported by this endpoint. Any text submitted will be the text
 * for all locales.
 */
export const useCreatePlan = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Plan,
      CreatePlanError,
      CreatePlanVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Plan,
    CreatePlanError,
    CreatePlanVariables
  >({
    mutationFn: (variables: CreatePlanVariables) =>
      fetchCreatePlan({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPlansError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetPlansVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get plans available to household (after eligibility has been set).
 */
export const fetchGetPlans = (
  variables: GetPlansVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.PlanMap, GetPlansError, undefined, {}, {}, {}>({
    url: '/selectsmart/plans',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get plans available to household (after eligibility has been set).
 */
export const useGetPlans = <TData = Schemas.PlanMap>(
  variables: GetPlansVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PlanMap, GetPlansError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/plans',
    operationId: 'getPlans',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.PlanMap, GetPlansError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetPlans({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type UpdatePlanPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  planId: string;
};

export type UpdatePlanError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdatePlanVariables = {
  body?: Schemas.PlanUpdate;
  pathParams: UpdatePlanPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update a plan in a household.
 *
 * Translations are not supported by this endpoint. Any text updated with it will
 * become the text for all locales.
 */
export const fetchUpdatePlan = (
  variables: UpdatePlanVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Plan,
    UpdatePlanError,
    Schemas.PlanUpdate,
    {},
    {},
    UpdatePlanPathParams
  >({
    url: '/selectsmart/plans/{planId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update a plan in a household.
 *
 * Translations are not supported by this endpoint. Any text updated with it will
 * become the text for all locales.
 */
export const useUpdatePlan = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Plan,
      UpdatePlanError,
      UpdatePlanVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Plan,
    UpdatePlanError,
    UpdatePlanVariables
  >({
    mutationFn: (variables: UpdatePlanVariables) =>
      fetchUpdatePlan({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPlanPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  planId: string;
};

export type GetPlanError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetPlanVariables = {
  pathParams: GetPlanPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get a plan FOR a household.
 */
export const fetchGetPlan = (
  variables: GetPlanVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Plan,
    GetPlanError,
    undefined,
    {},
    {},
    GetPlanPathParams
  >({
    url: '/selectsmart/plans/{planId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a plan FOR a household.
 */
export const useGetPlan = <TData = Schemas.Plan>(
  variables: GetPlanVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Plan, GetPlanError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/plans/{planId}',
    operationId: 'getPlan',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Plan, GetPlanError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetPlan({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type DeletePlanPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  planId: string;
};

export type DeletePlanError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeletePlanVariables = {
  pathParams: DeletePlanPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Delete a plan in a household.
 */
export const fetchDeletePlan = (
  variables: DeletePlanVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    undefined,
    DeletePlanError,
    undefined,
    {},
    {},
    DeletePlanPathParams
  >({
    url: '/selectsmart/plans/{planId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete a plan in a household.
 */
export const useDeletePlan = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePlanError,
      DeletePlanVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    undefined,
    DeletePlanError,
    DeletePlanVariables
  >({
    mutationFn: (variables: DeletePlanVariables) =>
      fetchDeletePlan({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateBenefitQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
};

export type CreateBenefitError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type CreateBenefitVariables = {
  body: Schemas.NewBenefit;
  queryParams?: CreateBenefitQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Create a new benefit for the household with optional default `plan_year`
 * if not provided in request body.
 *
 * WARNING: Unlike the `/planbenefits` endpoints, the default plan_year is `active`.
 */
export const fetchCreateBenefit = (
  variables: CreateBenefitVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Benefit,
    CreateBenefitError,
    Schemas.NewBenefit,
    {},
    CreateBenefitQueryParams,
    {}
  >({ url: '/selectsmart/benefits', method: 'post', ...variables, signal });

/**
 * Create a new benefit for the household with optional default `plan_year`
 * if not provided in request body.
 *
 * WARNING: Unlike the `/planbenefits` endpoints, the default plan_year is `active`.
 */
export const useCreateBenefit = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Benefit,
      CreateBenefitError,
      CreateBenefitVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Benefit,
    CreateBenefitError,
    CreateBenefitVariables
  >({
    mutationFn: (variables: CreateBenefitVariables) =>
      fetchCreateBenefit({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetBenefitsQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
};

export type GetBenefitsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetBenefitsVariables = {
  queryParams?: GetBenefitsQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get benefits available to household (after eligibility has been set)
 * for current guidance mode plan year.
 *
 * WARNING: Unlike the `/planbenefits` endpoints, the default plan_year is `active`.
 */
export const fetchGetBenefits = (
  variables: GetBenefitsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.BenefitMap,
    GetBenefitsError,
    undefined,
    {},
    GetBenefitsQueryParams,
    {}
  >({ url: '/selectsmart/benefits', method: 'get', ...variables, signal });

/**
 * Get benefits available to household (after eligibility has been set)
 * for current guidance mode plan year.
 *
 * WARNING: Unlike the `/planbenefits` endpoints, the default plan_year is `active`.
 */
export const useGetBenefits = <TData = Schemas.BenefitMap>(
  variables: GetBenefitsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.BenefitMap, GetBenefitsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/benefits',
    operationId: 'getBenefits',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.BenefitMap, GetBenefitsError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetBenefits({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type GetEligibleBenefitTypesQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
};

export type GetEligibleBenefitTypesError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetEligibleBenefitTypesVariables = {
  queryParams?: GetEligibleBenefitTypesQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get list of benefit types available to household (after eligibility has been set)
 * for current guidance mode plan year.
 *
 * WARNING: Unlike the `/planbenefits` endpoints, the default plan_year is `active`.
 */
export const fetchGetEligibleBenefitTypes = (
  variables: GetEligibleBenefitTypesVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.StringList,
    GetEligibleBenefitTypesError,
    undefined,
    {},
    GetEligibleBenefitTypesQueryParams,
    {}
  >({ url: '/selectsmart/benefit-types', method: 'get', ...variables, signal });

/**
 * Get list of benefit types available to household (after eligibility has been set)
 * for current guidance mode plan year.
 *
 * WARNING: Unlike the `/planbenefits` endpoints, the default plan_year is `active`.
 */
export const useGetEligibleBenefitTypes = <TData = Schemas.StringList>(
  variables: GetEligibleBenefitTypesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.StringList,
      GetEligibleBenefitTypesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/benefit-types',
    operationId: 'getEligibleBenefitTypes',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<
      Schemas.StringList,
      GetEligibleBenefitTypesError,
      TData
    >({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetEligibleBenefitTypes(
          { ...fetcherOptions, ...variables },
          signal,
        ),
      ...options,
      ...queryOptions,
    }),
  };
};

export type UpdateBenefitPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  benefitId: string;
};

export type UpdateBenefitError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateBenefitVariables = {
  body: Schemas.BenefitUpdate;
  pathParams: UpdateBenefitPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update a benefit in a household.
 */
export const fetchUpdateBenefit = (
  variables: UpdateBenefitVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Benefit,
    UpdateBenefitError,
    Schemas.BenefitUpdate,
    {},
    {},
    UpdateBenefitPathParams
  >({
    url: '/selectsmart/benefits/{benefitId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update a benefit in a household.
 */
export const useUpdateBenefit = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Benefit,
      UpdateBenefitError,
      UpdateBenefitVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Benefit,
    UpdateBenefitError,
    UpdateBenefitVariables
  >({
    mutationFn: (variables: UpdateBenefitVariables) =>
      fetchUpdateBenefit({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetBenefitPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  benefitId: string;
};

export type GetBenefitError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetBenefitVariables = {
  pathParams: GetBenefitPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get a benefit FOR a household.
 */
export const fetchGetBenefit = (
  variables: GetBenefitVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Benefit,
    GetBenefitError,
    undefined,
    {},
    {},
    GetBenefitPathParams
  >({
    url: '/selectsmart/benefits/{benefitId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a benefit FOR a household.
 */
export const useGetBenefit = <TData = Schemas.Benefit>(
  variables: GetBenefitVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Benefit, GetBenefitError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/benefits/{benefitId}',
    operationId: 'getBenefit',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Benefit, GetBenefitError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetBenefit({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type DeleteBenefitPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  benefitId: string;
};

export type DeleteBenefitError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeleteBenefitVariables = {
  pathParams: DeleteBenefitPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Delete a benefit in a household.
 */
export const fetchDeleteBenefit = (
  variables: DeleteBenefitVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    undefined,
    DeleteBenefitError,
    undefined,
    {},
    {},
    DeleteBenefitPathParams
  >({
    url: '/selectsmart/benefits/{benefitId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete a benefit in a household.
 */
export const useDeleteBenefit = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteBenefitError,
      DeleteBenefitVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    undefined,
    DeleteBenefitError,
    DeleteBenefitVariables
  >({
    mutationFn: (variables: DeleteBenefitVariables) =>
      fetchDeleteBenefit({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetBenefitUsesQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
  /**
   * The status of the benefit.
   */
  status?: 'change_allowed' | 'eligible' | 'enrolled' | 'selected';
};

export type GetBenefitUsesError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetBenefitUsesVariables = {
  queryParams?: GetBenefitUsesQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get household benefit uses with optional default `plan_year`
 * if not provided in request body.
 *
 * WARNING: Unlike the `/planbenefits` endpoints, the default plan_year is `active`.
 */
export const fetchGetBenefitUses = (
  variables: GetBenefitUsesVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.BenefitUseMap,
    GetBenefitUsesError,
    undefined,
    {},
    GetBenefitUsesQueryParams,
    {}
  >({ url: '/selectsmart/benefit-uses', method: 'get', ...variables, signal });

/**
 * Get household benefit uses with optional default `plan_year`
 * if not provided in request body.
 *
 * WARNING: Unlike the `/planbenefits` endpoints, the default plan_year is `active`.
 */
export const useGetBenefitUses = <TData = Schemas.BenefitUseMap>(
  variables: GetBenefitUsesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.BenefitUseMap,
      GetBenefitUsesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/benefit-uses',
    operationId: 'getBenefitUses',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.BenefitUseMap, GetBenefitUsesError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetBenefitUses({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type UpdateBenefitUsePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  benefitUseId: string;
};

export type UpdateBenefitUseError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateBenefitUseVariables = {
  body: Schemas.BenefitUseUpdate;
  pathParams: UpdateBenefitUsePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update a household benefit use.
 */
export const fetchUpdateBenefitUse = (
  variables: UpdateBenefitUseVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.BenefitUse,
    UpdateBenefitUseError,
    Schemas.BenefitUseUpdate,
    {},
    {},
    UpdateBenefitUsePathParams
  >({
    url: '/selectsmart/benefit-uses/{benefitUseId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update a household benefit use.
 */
export const useUpdateBenefitUse = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.BenefitUse,
      UpdateBenefitUseError,
      UpdateBenefitUseVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.BenefitUse,
    UpdateBenefitUseError,
    UpdateBenefitUseVariables
  >({
    mutationFn: (variables: UpdateBenefitUseVariables) =>
      fetchUpdateBenefitUse({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetBenefitUsePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  benefitUseId: string;
};

export type GetBenefitUseError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetBenefitUseVariables = {
  pathParams: GetBenefitUsePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get a household benefit use.
 */
export const fetchGetBenefitUse = (
  variables: GetBenefitUseVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.BenefitUse,
    GetBenefitUseError,
    undefined,
    {},
    {},
    GetBenefitUsePathParams
  >({
    url: '/selectsmart/benefit-uses/{benefitUseId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a household benefit use.
 */
export const useGetBenefitUse = <TData = Schemas.BenefitUse>(
  variables: GetBenefitUseVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.BenefitUse, GetBenefitUseError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/benefit-uses/{benefitUseId}',
    operationId: 'getBenefitUse',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.BenefitUse, GetBenefitUseError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetBenefitUse({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type DeleteBenefitUsePathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  benefitUseId: string;
};

export type DeleteBenefitUseError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeleteBenefitUseVariables = {
  pathParams: DeleteBenefitUsePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Delete a household benefit use.
 */
export const fetchDeleteBenefitUse = (
  variables: DeleteBenefitUseVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    undefined,
    DeleteBenefitUseError,
    undefined,
    {},
    {},
    DeleteBenefitUsePathParams
  >({
    url: '/selectsmart/benefit-uses/{benefitUseId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete a household benefit use.
 */
export const useDeleteBenefitUse = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteBenefitUseError,
      DeleteBenefitUseVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    undefined,
    DeleteBenefitUseError,
    DeleteBenefitUseVariables
  >({
    mutationFn: (variables: DeleteBenefitUseVariables) =>
      fetchDeleteBenefitUse({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RequestBundlesError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type RequestBundlesVariables = {
  body?: Schemas.BundleRequest;
} & SelectsmartContext['fetcherOptions'];

/**
 * Evaluate benefits and return results with optimized bundles.
 */
export const fetchRequestBundles = (
  variables: RequestBundlesVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Map,
    RequestBundlesError,
    Schemas.BundleRequest,
    {},
    {},
    {}
  >({ url: '/selectsmart/bundles', method: 'post', ...variables, signal });

/**
 * Evaluate benefits and return results with optimized bundles.
 */
export const useRequestBundles = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Map,
      RequestBundlesError,
      RequestBundlesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Map,
    RequestBundlesError,
    RequestBundlesVariables
  >({
    mutationFn: (variables: RequestBundlesVariables) =>
      fetchRequestBundles({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetLatestBundlesError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetLatestBundlesVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get most recent optimized bundles result.
 */
export const fetchGetLatestBundles = (
  variables: GetLatestBundlesVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.Map, GetLatestBundlesError, undefined, {}, {}, {}>({
    url: '/selectsmart/bundles',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get most recent optimized bundles result.
 */
export const useGetLatestBundles = <TData = Schemas.Map>(
  variables: GetLatestBundlesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Map, GetLatestBundlesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/bundles',
    operationId: 'getLatestBundles',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Map, GetLatestBundlesError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetLatestBundles({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type GetBundlesPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  bundlesId: string;
};

export type GetBundlesError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetBundlesVariables = {
  pathParams: GetBundlesPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get optimized bundles result.
 */
export const fetchGetBundles = (
  variables: GetBundlesVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Map,
    GetBundlesError,
    undefined,
    {},
    {},
    GetBundlesPathParams
  >({
    url: '/selectsmart/bundles/{bundlesId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get optimized bundles result.
 */
export const useGetBundles = <TData = Schemas.Map>(
  variables: GetBundlesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Map, GetBundlesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/bundles/{bundlesId}',
    operationId: 'getBundles',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Map, GetBundlesError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetBundles({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type SetBundlePathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  bundlesId: string;
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  bundleKey: string;
};

export type SetBundleError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SetBundleVariables = {
  pathParams: SetBundlePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Set a bundle choice for the household to use for next-dollar guidance.
 */
export const fetchSetBundle = (
  variables: SetBundleVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    SetBundleError,
    undefined,
    {},
    {},
    SetBundlePathParams
  >({
    url: '/selectsmart/bundles/{bundlesId}/{bundleKey}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Set a bundle choice for the household to use for next-dollar guidance.
 */
export const useSetBundle = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      SetBundleError,
      SetBundleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    SetBundleError,
    SetBundleVariables
  >({
    mutationFn: (variables: SetBundleVariables) =>
      fetchSetBundle({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetSelectedBundleError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetSelectedBundleVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get bundles result reduced to only contain the selected bundle.
 */
export const fetchGetSelectedBundle = (
  variables: GetSelectedBundleVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.Map, GetSelectedBundleError, undefined, {}, {}, {}>({
    url: '/selectsmart/bundle',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get bundles result reduced to only contain the selected bundle.
 */
export const useGetSelectedBundle = <TData = Schemas.Map>(
  variables: GetSelectedBundleVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Map, GetSelectedBundleError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/bundle',
    operationId: 'getSelectedBundle',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Map, GetSelectedBundleError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetSelectedBundle({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type GetAccountsQueryParams = {
  /**
   * A boolean switch/flag whose existence as a query parameter is interpreted
   * as True even if technically it is an empty string.
   */
  all?: boolean;
};

export type GetAccountsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetAccountsVariables = {
  queryParams?: GetAccountsQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get household accounts.
 */
export const fetchGetAccounts = (
  variables: GetAccountsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.AccountMap,
    GetAccountsError,
    undefined,
    {},
    GetAccountsQueryParams,
    {}
  >({ url: '/selectsmart/accounts', method: 'get', ...variables, signal });

/**
 * Get household accounts.
 */
export const useGetAccounts = <TData = Schemas.AccountMap>(
  variables: GetAccountsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.AccountMap, GetAccountsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/accounts',
    operationId: 'getAccounts',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.AccountMap, GetAccountsError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetAccounts({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type RefreshAccountsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type RefreshAccountsVariables = SelectsmartContext['fetcherOptions'];

/**
 * Refresh accounts in the household, updating external data and ensuring relevant accounts exist.
 */
export const fetchRefreshAccounts = (
  variables: RefreshAccountsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.AccountMap,
    RefreshAccountsError,
    undefined,
    {},
    {},
    {}
  >({ url: '/selectsmart/accounts', method: 'patch', ...variables, signal });

/**
 * Refresh accounts in the household, updating external data and ensuring relevant accounts exist.
 */
export const useRefreshAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AccountMap,
      RefreshAccountsError,
      RefreshAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.AccountMap,
    RefreshAccountsError,
    RefreshAccountsVariables
  >({
    mutationFn: (variables: RefreshAccountsVariables) =>
      fetchRefreshAccounts({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateAccountError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type CreateAccountVariables = {
  body: Schemas.NewAccount;
} & SelectsmartContext['fetcherOptions'];

/**
 * Create a new account in the household.
 */
export const fetchCreateAccount = (
  variables: CreateAccountVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Account,
    CreateAccountError,
    Schemas.NewAccount,
    {},
    {},
    {}
  >({ url: '/selectsmart/accounts', method: 'post', ...variables, signal });

/**
 * Create a new account in the household.
 */
export const useCreateAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Account,
      CreateAccountError,
      CreateAccountVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Account,
    CreateAccountError,
    CreateAccountVariables
  >({
    mutationFn: (variables: CreateAccountVariables) =>
      fetchCreateAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAccountPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  accountId: string;
};

export type GetAccountError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetAccountVariables = {
  pathParams: GetAccountPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get an account in a household.
 */
export const fetchGetAccount = (
  variables: GetAccountVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Account,
    GetAccountError,
    undefined,
    {},
    {},
    GetAccountPathParams
  >({
    url: '/selectsmart/accounts/{accountId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get an account in a household.
 */
export const useGetAccount = <TData = Schemas.Account>(
  variables: GetAccountVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Account, GetAccountError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/accounts/{accountId}',
    operationId: 'getAccount',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Account, GetAccountError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetAccount({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type UpdateAccountPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  accountId: string;
};

export type UpdateAccountError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateAccountVariables = {
  body: Schemas.AccountUpdate;
  pathParams: UpdateAccountPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update an account in a household.
 */
export const fetchUpdateAccount = (
  variables: UpdateAccountVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Account,
    UpdateAccountError,
    Schemas.AccountUpdate,
    {},
    {},
    UpdateAccountPathParams
  >({
    url: '/selectsmart/accounts/{accountId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update an account in a household.
 */
export const useUpdateAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Account,
      UpdateAccountError,
      UpdateAccountVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Account,
    UpdateAccountError,
    UpdateAccountVariables
  >({
    mutationFn: (variables: UpdateAccountVariables) =>
      fetchUpdateAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteAccountPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  accountId: string;
};

export type DeleteAccountError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeleteAccountVariables = {
  pathParams: DeleteAccountPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Delete an account in a household.
 */
export const fetchDeleteAccount = (
  variables: DeleteAccountVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    undefined,
    DeleteAccountError,
    undefined,
    {},
    {},
    DeleteAccountPathParams
  >({
    url: '/selectsmart/accounts/{accountId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete an account in a household.
 */
export const useDeleteAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteAccountError,
      DeleteAccountVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    undefined,
    DeleteAccountError,
    DeleteAccountVariables
  >({
    mutationFn: (variables: DeleteAccountVariables) =>
      fetchDeleteAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetGoalsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetGoalsVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get a household goal by type.
 */
export const fetchGetGoals = (
  variables: GetGoalsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.GoalMap, GetGoalsError, undefined, {}, {}, {}>({
    url: '/selectsmart/goals',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a household goal by type.
 */
export const useGetGoals = <TData = Schemas.GoalMap>(
  variables: GetGoalsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GoalMap, GetGoalsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/goals',
    operationId: 'getGoals',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.GoalMap, GetGoalsError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetGoals({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type RefreshGoalsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type RefreshGoalsVariables = SelectsmartContext['fetcherOptions'];

/**
 * Refresh household goals, creating default ones if necessary.
 */
export const fetchRefreshGoals = (
  variables: RefreshGoalsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.GoalMap, RefreshGoalsError, undefined, {}, {}, {}>({
    url: '/selectsmart/goals',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Refresh household goals, creating default ones if necessary.
 */
export const useRefreshGoals = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GoalMap,
      RefreshGoalsError,
      RefreshGoalsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.GoalMap,
    RefreshGoalsError,
    RefreshGoalsVariables
  >({
    mutationFn: (variables: RefreshGoalsVariables) =>
      fetchRefreshGoals({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetGoalPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  goalType: string;
};

export type GetGoalError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetGoalVariables = {
  pathParams: GetGoalPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get a household goal by type.
 */
export const fetchGetGoal = (
  variables: GetGoalVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Goal,
    GetGoalError,
    undefined,
    {},
    {},
    GetGoalPathParams
  >({
    url: '/selectsmart/goals/{goalType}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a household goal by type.
 */
export const useGetGoal = <TData = Schemas.Goal>(
  variables: GetGoalVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Goal, GetGoalError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/goals/{goalType}',
    operationId: 'getGoal',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Goal, GetGoalError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetGoal({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type UpdateGoalPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  goalType: string;
};

export type UpdateGoalError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateGoalVariables = {
  body: Schemas.GoalUpdate;
  pathParams: UpdateGoalPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update (or create) a household goal by type.
 */
export const fetchUpdateGoal = (
  variables: UpdateGoalVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Goal,
    UpdateGoalError,
    Schemas.GoalUpdate,
    {},
    {},
    UpdateGoalPathParams
  >({
    url: '/selectsmart/goals/{goalType}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update (or create) a household goal by type.
 */
export const useUpdateGoal = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Goal,
      UpdateGoalError,
      UpdateGoalVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Goal,
    UpdateGoalError,
    UpdateGoalVariables
  >({
    mutationFn: (variables: UpdateGoalVariables) =>
      fetchUpdateGoal({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RequestNextdollarError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type RequestNextdollarVariables = {
  body?: Schemas.NextDollarOptions;
} & SelectsmartContext['fetcherOptions'];

/**
 * Evaluate benefits and return results with optimized nextdollar.
 */
export const fetchRequestNextdollar = (
  variables: RequestNextdollarVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Map,
    RequestNextdollarError,
    Schemas.NextDollarOptions,
    {},
    {},
    {}
  >({ url: '/selectsmart/nextdollar', method: 'post', ...variables, signal });

/**
 * Evaluate benefits and return results with optimized nextdollar.
 */
export const useRequestNextdollar = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Map,
      RequestNextdollarError,
      RequestNextdollarVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Map,
    RequestNextdollarError,
    RequestNextdollarVariables
  >({
    mutationFn: (variables: RequestNextdollarVariables) =>
      fetchRequestNextdollar({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetLastestNextdollarError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetLastestNextdollarVariables =
  SelectsmartContext['fetcherOptions'];

/**
 * Get most recent nextdollar data (the status might not be "ready").
 */
export const fetchGetLastestNextdollar = (
  variables: GetLastestNextdollarVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Map,
    GetLastestNextdollarError,
    undefined,
    {},
    {},
    {}
  >({ url: '/selectsmart/nextdollar', method: 'get', ...variables, signal });

/**
 * Get most recent nextdollar data (the status might not be "ready").
 */
export const useGetLastestNextdollar = <TData = Schemas.Map>(
  variables: GetLastestNextdollarVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Map, GetLastestNextdollarError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/nextdollar',
    operationId: 'getLastestNextdollar',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Map, GetLastestNextdollarError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetLastestNextdollar({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type CancelNextdollarPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  nextdollarId: string;
};

export type CancelNextdollarError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type CancelNextdollarVariables = {
  pathParams: CancelNextdollarPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Cancel nextdollar optimization request.
 */
export const fetchCancelNextdollar = (
  variables: CancelNextdollarVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    CancelNextdollarError,
    undefined,
    {},
    {},
    CancelNextdollarPathParams
  >({
    url: '/selectsmart/nextdollar/{nextdollarId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Cancel nextdollar optimization request.
 */
export const useCancelNextdollar = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      CancelNextdollarError,
      CancelNextdollarVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    CancelNextdollarError,
    CancelNextdollarVariables
  >({
    mutationFn: (variables: CancelNextdollarVariables) =>
      fetchCancelNextdollar({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetNextdollarPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  nextdollarId: string;
};

export type GetNextdollarError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetNextdollarVariables = {
  pathParams: GetNextdollarPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get optimized nextdollar result.
 */
export const fetchGetNextdollar = (
  variables: GetNextdollarVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Map,
    GetNextdollarError,
    undefined,
    {},
    {},
    GetNextdollarPathParams
  >({
    url: '/selectsmart/nextdollar/{nextdollarId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get optimized nextdollar result.
 */
export const useGetNextdollar = <TData = Schemas.Map>(
  variables: GetNextdollarVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Map, GetNextdollarError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/nextdollar/{nextdollarId}',
    operationId: 'getNextdollar',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Map, GetNextdollarError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetNextdollar({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type UpdateNextdollarPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  nextdollarId: string;
};

export type UpdateNextdollarError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateNextdollarVariables = {
  body: Schemas.NextDollarUpdate;
  pathParams: UpdateNextdollarPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update nextdollar result with new budget.
 */
export const fetchUpdateNextdollar = (
  variables: UpdateNextdollarVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Map,
    UpdateNextdollarError,
    Schemas.NextDollarUpdate,
    {},
    {},
    UpdateNextdollarPathParams
  >({
    url: '/selectsmart/nextdollar/{nextdollarId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update nextdollar result with new budget.
 */
export const useUpdateNextdollar = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Map,
      UpdateNextdollarError,
      UpdateNextdollarVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Map,
    UpdateNextdollarError,
    UpdateNextdollarVariables
  >({
    mutationFn: (variables: UpdateNextdollarVariables) =>
      fetchUpdateNextdollar({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateActionplanError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type CreateActionplanVariables = {
  body: Schemas.ActionPlanRequest;
} & SelectsmartContext['fetcherOptions'];

/**
 * Request an action plan for a specific budget.
 */
export const fetchCreateActionplan = (
  variables: CreateActionplanVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.ActionPlan,
    CreateActionplanError,
    Schemas.ActionPlanRequest,
    {},
    {},
    {}
  >({ url: '/selectsmart/actionplan', method: 'patch', ...variables, signal });

/**
 * Request an action plan for a specific budget.
 */
export const useCreateActionplan = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ActionPlan,
      CreateActionplanError,
      CreateActionplanVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.ActionPlan,
    CreateActionplanError,
    CreateActionplanVariables
  >({
    mutationFn: (variables: CreateActionplanVariables) =>
      fetchCreateActionplan({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetActionplanError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetActionplanVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get latest created action plan.
 */
export const fetchGetActionplan = (
  variables: GetActionplanVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.ActionPlan,
    GetActionplanError,
    undefined,
    {},
    {},
    {}
  >({ url: '/selectsmart/actionplan', method: 'get', ...variables, signal });

/**
 * Get latest created action plan.
 */
export const useGetActionplan = <TData = Schemas.ActionPlan>(
  variables: GetActionplanVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ActionPlan, GetActionplanError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/actionplan',
    operationId: 'getActionplan',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.ActionPlan, GetActionplanError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetActionplan({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type SendActionplanEmailError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SendActionplanEmailVariables = {
  body?: Schemas.ActionPlanSendEmail;
} & SelectsmartContext['fetcherOptions'];

/**
 * Request an email with a link back to the action plan.
 */
export const fetchSendActionplanEmail = (
  variables: SendActionplanEmailVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.SendActionPlanResponse,
    SendActionplanEmailError,
    Schemas.ActionPlanSendEmail,
    {},
    {},
    {}
  >({
    url: '/selectsmart/actionplan/send-email',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Request an email with a link back to the action plan.
 */
export const useSendActionplanEmail = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SendActionPlanResponse,
      SendActionplanEmailError,
      SendActionplanEmailVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.SendActionPlanResponse,
    SendActionplanEmailError,
    SendActionplanEmailVariables
  >({
    mutationFn: (variables: SendActionplanEmailVariables) =>
      fetchSendActionplanEmail({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateActionsgroupPathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  actionplanId: string;
  /**
   * ActionsGroupName
   */
  actionsGroup: 'benadmin' | 'emergencyfund' | 'esrp' | 'esrp_spouse' | 'ira';
};

export type UpdateActionsgroupError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateActionsgroupVariables = {
  body?: Schemas.ActionsGroupUpdate;
  pathParams: UpdateActionsgroupPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Set action plan group completion.
 */
export const fetchUpdateActionsgroup = (
  variables: UpdateActionsgroupVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.ActionPlan,
    UpdateActionsgroupError,
    Schemas.ActionsGroupUpdate,
    {},
    {},
    UpdateActionsgroupPathParams
  >({
    url: '/selectsmart/actionplans/{actionplanId}/{actionsGroup}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Set action plan group completion.
 */
export const useUpdateActionsgroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ActionPlan,
      UpdateActionsgroupError,
      UpdateActionsgroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.ActionPlan,
    UpdateActionsgroupError,
    UpdateActionsgroupVariables
  >({
    mutationFn: (variables: UpdateActionsgroupVariables) =>
      fetchUpdateActionsgroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LoadHouseholdError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type LoadHouseholdVariables = SelectsmartContext['fetcherOptions'];

/**
 * Enable integrations and load household data.
 */
export const fetchLoadHousehold = (
  variables: LoadHouseholdVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    LoadHouseholdError,
    undefined,
    {},
    {},
    {}
  >({ url: '/selectsmart/integrations', method: 'post', ...variables, signal });

/**
 * Enable integrations and load household data.
 */
export const useLoadHousehold = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      LoadHouseholdError,
      LoadHouseholdVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    LoadHouseholdError,
    LoadHouseholdVariables
  >({
    mutationFn: (variables: LoadHouseholdVariables) =>
      fetchLoadHousehold({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SubmitVoyaChangeDeprecatedError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SubmitVoyaChangeDeprecatedVariables = {
  body: Schemas.ContributionChangeRequest;
} & SelectsmartContext['fetcherOptions'];

/**
 * Enable integrations and load household data.
 */
export const fetchSubmitVoyaChangeDeprecated = (
  variables: SubmitVoyaChangeDeprecatedVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.ContributionChangeConfirmation,
    SubmitVoyaChangeDeprecatedError,
    Schemas.ContributionChangeRequest,
    {},
    {},
    {}
  >({
    url: '/selectsmart/integrations/voya',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Enable integrations and load household data.
 */
export const useSubmitVoyaChangeDeprecated = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContributionChangeConfirmation,
      SubmitVoyaChangeDeprecatedError,
      SubmitVoyaChangeDeprecatedVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.ContributionChangeConfirmation,
    SubmitVoyaChangeDeprecatedError,
    SubmitVoyaChangeDeprecatedVariables
  >({
    mutationFn: (variables: SubmitVoyaChangeDeprecatedVariables) =>
      fetchSubmitVoyaChangeDeprecated({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SubmitVoyaChangeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SubmitVoyaChangeVariables = {
  body: Schemas.ContributionChangeRequest;
} & SelectsmartContext['fetcherOptions'];

/**
 * Submit a contribution change request to Voya Retirement.
 */
export const fetchSubmitVoyaChange = (
  variables: SubmitVoyaChangeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.ContributionChangeConfirmation,
    SubmitVoyaChangeError,
    Schemas.ContributionChangeRequest,
    {},
    {},
    {}
  >({
    url: '/selectsmart/integrations/voya/contribution-change',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Submit a contribution change request to Voya Retirement.
 */
export const useSubmitVoyaChange = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContributionChangeConfirmation,
      SubmitVoyaChangeError,
      SubmitVoyaChangeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.ContributionChangeConfirmation,
    SubmitVoyaChangeError,
    SubmitVoyaChangeVariables
  >({
    mutationFn: (variables: SubmitVoyaChangeVariables) =>
      fetchSubmitVoyaChange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CollectVoyaEnrollNudgeError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type CollectVoyaEnrollNudgeVariables = {
  body: Schemas.EnrollNudgeRequest;
} & SelectsmartContext['fetcherOptions'];

/**
 * Enable integrations and load household data.
 */
export const fetchCollectVoyaEnrollNudge = (
  variables: CollectVoyaEnrollNudgeVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    CollectVoyaEnrollNudgeError,
    Schemas.EnrollNudgeRequest,
    {},
    {},
    {}
  >({
    url: '/selectsmart/integrations/voya/enroll-nudge',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Enable integrations and load household data.
 */
export const useCollectVoyaEnrollNudge = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      CollectVoyaEnrollNudgeError,
      CollectVoyaEnrollNudgeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    CollectVoyaEnrollNudgeError,
    CollectVoyaEnrollNudgeVariables
  >({
    mutationFn: (variables: CollectVoyaEnrollNudgeVariables) =>
      fetchCollectVoyaEnrollNudge({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPlanbenefitsQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
  /**
   * The role of the person bringing the benefits to the household. The `client` is
   * the primary user whose employer is sponsoring the service and `spouse` is their
   * spouse or partner.
   */
  person_role?: 'client' | 'spouse';
  /**
   * The status of the benefit.
   */
  status?: 'change_allowed' | 'eligible' | 'enrolled' | 'selected';
};

export type GetPlanbenefitsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetPlanbenefitsVariables = {
  queryParams?: GetPlanbenefitsQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get benefits available to household.
 *
 * WARNING: Unlike the `/benefits` endpoints, the default plan_year is `current`.
 */
export const fetchGetPlanbenefits = (
  variables: GetPlanbenefitsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.PlanBenefitList,
    GetPlanbenefitsError,
    undefined,
    {},
    GetPlanbenefitsQueryParams,
    {}
  >({ url: '/selectsmart/planbenefits', method: 'get', ...variables, signal });

/**
 * Get benefits available to household.
 *
 * WARNING: Unlike the `/benefits` endpoints, the default plan_year is `current`.
 */
export const useGetPlanbenefits = <TData = Schemas.PlanBenefitList>(
  variables: GetPlanbenefitsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlanBenefitList,
      GetPlanbenefitsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/planbenefits',
    operationId: 'getPlanbenefits',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<
      Schemas.PlanBenefitList,
      GetPlanbenefitsError,
      TData
    >({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetPlanbenefits({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type CreatePlanbenefitQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
};

export type CreatePlanbenefitError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type CreatePlanbenefitVariables = {
  body: Schemas.NewPlanBenefit;
  queryParams?: CreatePlanbenefitQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Create a new planbenefit for the household with optional default `plan_year`
 * if not provided in request body. Set `plan_year` to `active` to create
 * for the active guidance mode plan year.
 *
 * Translations are not supported by this endpoint. Any text submitted with it will
 * become the text for all locales.
 *
 * WARNING: Unlike the `/benefits` endpoints, the default plan_year is `current`.
 */
export const fetchCreatePlanbenefit = (
  variables: CreatePlanbenefitVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.PlanBenefit,
    CreatePlanbenefitError,
    Schemas.NewPlanBenefit,
    {},
    CreatePlanbenefitQueryParams,
    {}
  >({ url: '/selectsmart/planbenefits', method: 'post', ...variables, signal });

/**
 * Create a new planbenefit for the household with optional default `plan_year`
 * if not provided in request body. Set `plan_year` to `active` to create
 * for the active guidance mode plan year.
 *
 * Translations are not supported by this endpoint. Any text submitted with it will
 * become the text for all locales.
 *
 * WARNING: Unlike the `/benefits` endpoints, the default plan_year is `current`.
 */
export const useCreatePlanbenefit = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlanBenefit,
      CreatePlanbenefitError,
      CreatePlanbenefitVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.PlanBenefit,
    CreatePlanbenefitError,
    CreatePlanbenefitVariables
  >({
    mutationFn: (variables: CreatePlanbenefitVariables) =>
      fetchCreatePlanbenefit({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPlanbenefitPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  planbenefitId: string;
};

export type GetPlanbenefitError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetPlanbenefitVariables = {
  pathParams: GetPlanbenefitPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get a planbenefit for a household.
 */
export const fetchGetPlanbenefit = (
  variables: GetPlanbenefitVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.PlanBenefit,
    GetPlanbenefitError,
    undefined,
    {},
    {},
    GetPlanbenefitPathParams
  >({
    url: '/selectsmart/planbenefits/{planbenefitId}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a planbenefit for a household.
 */
export const useGetPlanbenefit = <TData = Schemas.PlanBenefit>(
  variables: GetPlanbenefitVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PlanBenefit, GetPlanbenefitError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/planbenefits/{planbenefitId}',
    operationId: 'getPlanbenefit',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.PlanBenefit, GetPlanbenefitError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetPlanbenefit({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type DeletePlanbenefitPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  planbenefitId: string;
};

export type DeletePlanbenefitError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeletePlanbenefitVariables = {
  pathParams: DeletePlanbenefitPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Delete a planbenefit for a household.
 */
export const fetchDeletePlanbenefit = (
  variables: DeletePlanbenefitVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    DeletePlanbenefitError,
    undefined,
    {},
    {},
    DeletePlanbenefitPathParams
  >({
    url: '/selectsmart/planbenefits/{planbenefitId}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete a planbenefit for a household.
 */
export const useDeletePlanbenefit = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      DeletePlanbenefitError,
      DeletePlanbenefitVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    DeletePlanbenefitError,
    DeletePlanbenefitVariables
  >({
    mutationFn: (variables: DeletePlanbenefitVariables) =>
      fetchDeletePlanbenefit({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdatePlanbenefitPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  planbenefitId: string;
};

export type UpdatePlanbenefitError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdatePlanbenefitVariables = {
  body: Schemas.PlanBenefitUpdate;
  pathParams: UpdatePlanbenefitPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update a planbenefit.
 *
 * Translations are not supported by this endpoint. Any text updated with it will
 * become the text for all locales.
 */
export const fetchUpdatePlanbenefit = (
  variables: UpdatePlanbenefitVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.PlanBenefit,
    UpdatePlanbenefitError,
    Schemas.PlanBenefitUpdate,
    {},
    {},
    UpdatePlanbenefitPathParams
  >({
    url: '/selectsmart/planbenefits/{planbenefitId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update a planbenefit.
 *
 * Translations are not supported by this endpoint. Any text updated with it will
 * become the text for all locales.
 */
export const useUpdatePlanbenefit = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlanBenefit,
      UpdatePlanbenefitError,
      UpdatePlanbenefitVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.PlanBenefit,
    UpdatePlanbenefitError,
    UpdatePlanbenefitVariables
  >({
    mutationFn: (variables: UpdatePlanbenefitVariables) =>
      fetchUpdatePlanbenefit({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetBenefitssummaryQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
};

export type GetBenefitssummaryError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetBenefitssummaryVariables = {
  queryParams?: GetBenefitssummaryQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get benefit and plan summaries for household. Without parameters, this returns
 * the enrolled benefits for the current plan year. Requires benadmin integration
 * for these to be populated.
 */
export const fetchGetBenefitssummary = (
  variables: GetBenefitssummaryVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Map,
    GetBenefitssummaryError,
    undefined,
    {},
    GetBenefitssummaryQueryParams,
    {}
  >({
    url: '/selectsmart/benefits-summary',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get benefit and plan summaries for household. Without parameters, this returns
 * the enrolled benefits for the current plan year. Requires benadmin integration
 * for these to be populated.
 */
export const useGetBenefitssummary = <TData = Schemas.Map>(
  variables: GetBenefitssummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Map, GetBenefitssummaryError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/benefits-summary',
    operationId: 'getBenefitssummary',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Map, GetBenefitssummaryError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetBenefitssummary({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type GetActionsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetActionsVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get actions available to household.
 */
export const fetchGetActions = (
  variables: GetActionsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.ActionList, GetActionsError, undefined, {}, {}, {}>({
    url: '/selectsmart/actions',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get actions available to household.
 */
export const useGetActions = <TData = Schemas.ActionList>(
  variables: GetActionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ActionList, GetActionsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/actions',
    operationId: 'getActions',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.ActionList, GetActionsError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetActions({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type UpdateActionsPathParams = {
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  actionId: string;
};

export type UpdateActionsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateActionsVariables = {
  body?: Schemas.ActionUpdateUpdate;
  pathParams: UpdateActionsPathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update an action's completed_at/ignored_at and update the actionplan if necessary.
 */
export const fetchUpdateActions = (
  variables: UpdateActionsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Action,
    UpdateActionsError,
    Schemas.ActionUpdateUpdate,
    {},
    {},
    UpdateActionsPathParams
  >({
    url: '/selectsmart/actions/{actionId}',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update an action's completed_at/ignored_at and update the actionplan if necessary.
 */
export const useUpdateActions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Action,
      UpdateActionsError,
      UpdateActionsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Action,
    UpdateActionsError,
    UpdateActionsVariables
  >({
    mutationFn: (variables: UpdateActionsVariables) =>
      fetchUpdateActions({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SubmitBenefitfocusBundlePathParams = {
  /**
   * UUID
   *
   * @format uuid
   */
  bundlesId: string;
  /**
   * A short alpha-numeric string commonly used for url-safe identifiers.
   *
   * @minLength 1
   * @maxLength 128
   * @pattern [a-zA-Z0-9_:-]+
   */
  bundleKey: string;
};

export type SubmitBenefitfocusBundleError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SubmitBenefitfocusBundleVariables = {
  pathParams: SubmitBenefitfocusBundlePathParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Submit bundle to Benefitfocus benefits list.
 */
export const fetchSubmitBenefitfocusBundle = (
  variables: SubmitBenefitfocusBundleVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    SubmitBenefitfocusBundleError,
    undefined,
    {},
    {},
    SubmitBenefitfocusBundlePathParams
  >({
    url: '/selectsmart/integrations/benefitfocus/submit-bundle/{bundlesId}/{bundleKey}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Submit bundle to Benefitfocus benefits list.
 */
export const useSubmitBenefitfocusBundle = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      SubmitBenefitfocusBundleError,
      SubmitBenefitfocusBundleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    SubmitBenefitfocusBundleError,
    SubmitBenefitfocusBundleVariables
  >({
    mutationFn: (variables: SubmitBenefitfocusBundleVariables) =>
      fetchSubmitBenefitfocusBundle({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateOptinError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateOptinVariables = {
  body: Schemas.ClaimsOptIn;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update opt-in for claims data.
 */
export const fetchUpdateOptin = (
  variables: UpdateOptinVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.OperationStatus,
    UpdateOptinError,
    Schemas.ClaimsOptIn,
    {},
    {},
    {}
  >({
    url: '/selectsmart/claims/optin',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update opt-in for claims data.
 */
export const useUpdateOptin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OperationStatus,
      UpdateOptinError,
      UpdateOptinVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.OperationStatus,
    UpdateOptinError,
    UpdateOptinVariables
  >({
    mutationFn: (variables: UpdateOptinVariables) =>
      fetchUpdateOptin({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RefreshClaimsSummaryError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type RefreshClaimsSummaryVariables =
  SelectsmartContext['fetcherOptions'];

/**
 * Refresh claims summary data if stale. Return latest data.
 */
export const fetchRefreshClaimsSummary = (
  variables: RefreshClaimsSummaryVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.Map,
    RefreshClaimsSummaryError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/selectsmart/claims/summary',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Refresh claims summary data if stale. Return latest data.
 */
export const useRefreshClaimsSummary = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Map,
      RefreshClaimsSummaryError,
      RefreshClaimsSummaryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.Map,
    RefreshClaimsSummaryError,
    RefreshClaimsSummaryVariables
  >({
    mutationFn: (variables: RefreshClaimsSummaryVariables) =>
      fetchRefreshClaimsSummary({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetClaimsSummaryError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetClaimsSummaryVariables = SelectsmartContext['fetcherOptions'];

/**
 * Return latest claims summary data.
 */
export const fetchGetClaimsSummary = (
  variables: GetClaimsSummaryVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.Map, GetClaimsSummaryError, undefined, {}, {}, {}>({
    url: '/selectsmart/claims/summary',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Return latest claims summary data.
 */
export const useGetClaimsSummary = <TData = Schemas.Map>(
  variables: GetClaimsSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Map, GetClaimsSummaryError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/claims/summary',
    operationId: 'getClaimsSummary',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.Map, GetClaimsSummaryError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetClaimsSummary({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type RefreshLdiQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
};

export type RefreshLdiError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type RefreshLdiVariables = {
  queryParams?: RefreshLdiQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Refresh life & disability insurances data with most recent household data.
 */
export const fetchRefreshLdi = (
  variables: RefreshLdiVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.LDIData,
    RefreshLdiError,
    undefined,
    {},
    RefreshLdiQueryParams,
    {}
  >({ url: '/selectsmart/ldi', method: 'patch', ...variables, signal });

/**
 * Refresh life & disability insurances data with most recent household data.
 */
export const useRefreshLdi = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.LDIData,
      RefreshLdiError,
      RefreshLdiVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.LDIData,
    RefreshLdiError,
    RefreshLdiVariables
  >({
    mutationFn: (variables: RefreshLdiVariables) =>
      fetchRefreshLdi({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetLdiQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
};

export type GetLdiError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetLdiVariables = {
  queryParams?: GetLdiQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Get available life & disability insurances data.
 */
export const fetchGetLdi = (variables: GetLdiVariables, signal?: AbortSignal) =>
  selectsmartFetch<
    Schemas.LDIData,
    GetLdiError,
    undefined,
    {},
    GetLdiQueryParams,
    {}
  >({ url: '/selectsmart/ldi', method: 'get', ...variables, signal });

/**
 * Get available life & disability insurances data.
 */
export const useGetLdi = <TData = Schemas.LDIData>(
  variables: GetLdiVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.LDIData, GetLdiError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/ldi',
    operationId: 'getLdi',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.LDIData, GetLdiError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetLdi({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type SetLdiSelectionsQueryParams = {
  /**
   * The plan year as a 4-digit ISO-year (e.g. `2022`), `current`, `next`, `prior`, or `active`
   * where `next` is the "next available" which is the current plan year until open enrollment
   * starts, then it will be the "next" plan year. If the "next" plan year has not been loaded
   * (e.g. the employer is not continuing), then it will be the current plan year. For each
   * household individually, the `active` plan year is the one determined by the household's
   * current guidance mode.
   *
   * @pattern (\d\d\d\d)|current|next|prior|active
   */
  plan_year?: string;
};

export type SetLdiSelectionsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SetLdiSelectionsVariables = {
  body?: Schemas.LDISelections;
  queryParams?: SetLdiSelectionsQueryParams;
} & SelectsmartContext['fetcherOptions'];

/**
 * Set benefit_use, update action plan, and create actions for selected L&DI
 * benefits while unselecting others of the same type since we only allow one
 * of each type to be selected.
 */
export const fetchSetLdiSelections = (
  variables: SetLdiSelectionsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.LDIData,
    SetLdiSelectionsError,
    Schemas.LDISelections,
    {},
    SetLdiSelectionsQueryParams,
    {}
  >({ url: '/selectsmart/ldi', method: 'put', ...variables, signal });

/**
 * Set benefit_use, update action plan, and create actions for selected L&DI
 * benefits while unselecting others of the same type since we only allow one
 * of each type to be selected.
 */
export const useSetLdiSelections = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.LDIData,
      SetLdiSelectionsError,
      SetLdiSelectionsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.LDIData,
    SetLdiSelectionsError,
    SetLdiSelectionsVariables
  >({
    mutationFn: (variables: SetLdiSelectionsVariables) =>
      fetchSetLdiSelections({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SetSummarizedBenefitsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SetSummarizedBenefitsVariables = {
  body?: Schemas.SummarizedBenefits;
} & SelectsmartContext['fetcherOptions'];

/**
 * Create or replace returning user healthcare summary.
 */
export const fetchSetSummarizedBenefits = (
  variables: SetSummarizedBenefitsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.SummarizedBenefits,
    SetSummarizedBenefitsError,
    Schemas.SummarizedBenefits,
    {},
    {},
    {}
  >({
    url: '/selectsmart/summarized-benefits',
    method: 'put',
    ...variables,
    signal,
  });

/**
 * Create or replace returning user healthcare summary.
 */
export const useSetSummarizedBenefits = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SummarizedBenefits,
      SetSummarizedBenefitsError,
      SetSummarizedBenefitsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.SummarizedBenefits,
    SetSummarizedBenefitsError,
    SetSummarizedBenefitsVariables
  >({
    mutationFn: (variables: SetSummarizedBenefitsVariables) =>
      fetchSetSummarizedBenefits({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateSummarizedBenefitsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type UpdateSummarizedBenefitsVariables = {
  body?: Schemas.SummarizedBenefitsUpdate;
} & SelectsmartContext['fetcherOptions'];

/**
 * Update a returning user healthcare summary.
 */
export const fetchUpdateSummarizedBenefits = (
  variables: UpdateSummarizedBenefitsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.SummarizedBenefits,
    UpdateSummarizedBenefitsError,
    Schemas.SummarizedBenefitsUpdate,
    {},
    {},
    {}
  >({
    url: '/selectsmart/summarized-benefits',
    method: 'patch',
    ...variables,
    signal,
  });

/**
 * Update a returning user healthcare summary.
 */
export const useUpdateSummarizedBenefits = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SummarizedBenefits,
      UpdateSummarizedBenefitsError,
      UpdateSummarizedBenefitsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.SummarizedBenefits,
    UpdateSummarizedBenefitsError,
    UpdateSummarizedBenefitsVariables
  >({
    mutationFn: (variables: UpdateSummarizedBenefitsVariables) =>
      fetchUpdateSummarizedBenefits({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetSummarizedBenefitsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type GetSummarizedBenefitsVariables =
  SelectsmartContext['fetcherOptions'];

/**
 * Get a returning user healthcare summary.
 */
export const fetchGetSummarizedBenefits = (
  variables: GetSummarizedBenefitsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.SummarizedBenefits,
    GetSummarizedBenefitsError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/selectsmart/summarized-benefits',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get a returning user healthcare summary.
 */
export const useGetSummarizedBenefits = <TData = Schemas.SummarizedBenefits>(
  variables: GetSummarizedBenefitsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.SummarizedBenefits,
      GetSummarizedBenefitsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/summarized-benefits',
    operationId: 'getSummarizedBenefits',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<
      Schemas.SummarizedBenefits,
      GetSummarizedBenefitsError,
      TData
    >({
      queryKey,
      queryFn: ({ signal }) =>
        fetchGetSummarizedBenefits({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type DeleteSummarizedBenefitsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type DeleteSummarizedBenefitsVariables =
  SelectsmartContext['fetcherOptions'];

/**
 * Delete returning user healthcare summary.
 */
export const fetchDeleteSummarizedBenefits = (
  variables: DeleteSummarizedBenefitsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    undefined,
    DeleteSummarizedBenefitsError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/selectsmart/summarized-benefits',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete returning user healthcare summary.
 */
export const useDeleteSummarizedBenefits = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteSummarizedBenefitsError,
      DeleteSummarizedBenefitsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    undefined,
    DeleteSummarizedBenefitsError,
    DeleteSummarizedBenefitsVariables
  >({
    mutationFn: (variables: DeleteSummarizedBenefitsVariables) =>
      fetchDeleteSummarizedBenefits({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SummarizedBenefitsEstimateError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type SummarizedBenefitsEstimateVariables = {
  body?: Schemas.SummarizedBenefits;
} & SelectsmartContext['fetcherOptions'];

/**
 * Provide engine-calculated estimated OOP expenses and premiums for use in
 * summarized benefits for midyear update.
 *
 * Behavior is controlled by two fields in `summarized_benefits` that indicate whether there
 * have been user edits to the estimated OOP and/or estimated premiums fields
 *
 *   * is_estimated_annual_oop_expenses_edited
 *   * is_estimated_monthly_premiums_edited
 *
 * If both are `True` then it means the user has made changes to both fields.  The
 * engine estimates will not be run and the summarized_benefits object will be returned unchanged.
 *
 * If either or both are `False` then the corresponding field(s) in the returned summarized_benefits will contain
 * the corresponding value(s) from the engine's estimate.
 */
export const fetchSummarizedBenefitsEstimate = (
  variables: SummarizedBenefitsEstimateVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<
    Schemas.SummarizedBenefits,
    SummarizedBenefitsEstimateError,
    Schemas.SummarizedBenefits,
    {},
    {},
    {}
  >({
    url: '/selectsmart/summarized-benefits/estimate',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Provide engine-calculated estimated OOP expenses and premiums for use in
 * summarized benefits for midyear update.
 *
 * Behavior is controlled by two fields in `summarized_benefits` that indicate whether there
 * have been user edits to the estimated OOP and/or estimated premiums fields
 *
 *   * is_estimated_annual_oop_expenses_edited
 *   * is_estimated_monthly_premiums_edited
 *
 * If both are `True` then it means the user has made changes to both fields.  The
 * engine estimates will not be run and the summarized_benefits object will be returned unchanged.
 *
 * If either or both are `False` then the corresponding field(s) in the returned summarized_benefits will contain
 * the corresponding value(s) from the engine's estimate.
 */
export const useSummarizedBenefitsEstimate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SummarizedBenefits,
      SummarizedBenefitsEstimateError,
      SummarizedBenefitsEstimateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useSelectsmartContext();
  return reactQuery.useMutation<
    Schemas.SummarizedBenefits,
    SummarizedBenefitsEstimateError,
    SummarizedBenefitsEstimateVariables
  >({
    mutationFn: (variables: SummarizedBenefitsEstimateVariables) =>
      fetchSummarizedBenefitsEstimate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type IrsLimitsError = Fetcher.ErrorWrapper<
  | {
      status: ClientErrorStatus;
      payload: Schemas.RequestError;
    }
  | {
      status: ServerErrorStatus;
      payload: Schemas.ResponseError;
    }
>;

export type IrsLimitsVariables = SelectsmartContext['fetcherOptions'];

/**
 * Get IRS limits for the client and spouse
 */
export const fetchIrsLimits = (
  variables: IrsLimitsVariables,
  signal?: AbortSignal,
) =>
  selectsmartFetch<Schemas.IrsLimits, IrsLimitsError, undefined, {}, {}, {}>({
    url: '/selectsmart/irs-limits',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Get IRS limits for the client and spouse
 */
export const useIrsLimits = <TData = Schemas.IrsLimits>(
  variables: IrsLimitsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.IrsLimits, IrsLimitsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useSelectsmartContext(options);
  const queryKey = queryKeyFn({
    path: '/selectsmart/irs-limits',
    operationId: 'irsLimits',
    variables,
  });
  return {
    queryKey,
    ...reactQuery.useQuery<Schemas.IrsLimits, IrsLimitsError, TData>({
      queryKey,
      queryFn: ({ signal }) =>
        fetchIrsLimits({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }),
  };
};

export type QueryOperation =
  | {
      path: '/auth/invites/{inviteCode}';
      operationId: 'getInvite';
      variables: GetInviteVariables;
    }
  | {
      path: '/user';
      operationId: 'getUser';
      variables: GetUserVariables;
    }
  | {
      path: '/user/groups/{groupId}/privileges';
      operationId: 'getGroupPrivileges';
      variables: GetGroupPrivilegesVariables;
    }
  | {
      path: '/selectsmart/employers/{employerId}/{planYear}';
      operationId: 'getEmployerBenefitsYear';
      variables: GetEmployerBenefitsYearVariables;
    }
  | {
      path: '/selectsmart/employers/{employerId}';
      operationId: 'getEmployer';
      variables: GetEmployerVariables;
    }
  | {
      path: '/selectsmart/employer';
      operationId: 'getBenefitsYear';
      variables: GetBenefitsYearVariables;
    }
  | {
      path: '/selectsmart/people';
      operationId: 'getPeople';
      variables: GetPeopleVariables;
    }
  | {
      path: '/selectsmart/people/{personId}';
      operationId: 'getPerson';
      variables: GetPersonVariables;
    }
  | {
      path: '/selectsmart/household';
      operationId: 'getHousehold';
      variables: GetHouseholdVariables;
    }
  | {
      path: '/selectsmart/household/income-levels';
      operationId: 'getHouseholdIncomeLevels';
      variables: GetHouseholdIncomeLevelsVariables;
    }
  | {
      path: '/selectsmart/incomes/{personId}';
      operationId: 'getIncome';
      variables: GetIncomeVariables;
    }
  | {
      path: '/selectsmart/incomes';
      operationId: 'getIncomes';
      variables: GetIncomesVariables;
    }
  | {
      path: '/selectsmart/tobacco-use/{personId}';
      operationId: 'getTobaccoUse';
      variables: GetTobaccoUseVariables;
    }
  | {
      path: '/selectsmart/tobacco-use';
      operationId: 'getTobaccoUses';
      variables: GetTobaccoUsesVariables;
    }
  | {
      path: '/selectsmart/healthcare/{personId}';
      operationId: 'getHealthcare';
      variables: GetHealthcareVariables;
    }
  | {
      path: '/selectsmart/healthcare';
      operationId: 'getHealthcares';
      variables: GetHealthcaresVariables;
    }
  | {
      path: '/selectsmart/assumptions/usage-levels';
      operationId: 'getUsageLevelAssumptions';
      variables: GetUsageLevelAssumptionsVariables;
    }
  | {
      path: '/selectsmart/assumptions/typical-services';
      operationId: 'getTypicalServices';
      variables: GetTypicalServicesVariables;
    }
  | {
      path: '/selectsmart/plans';
      operationId: 'getPlans';
      variables: GetPlansVariables;
    }
  | {
      path: '/selectsmart/plans/{planId}';
      operationId: 'getPlan';
      variables: GetPlanVariables;
    }
  | {
      path: '/selectsmart/benefits';
      operationId: 'getBenefits';
      variables: GetBenefitsVariables;
    }
  | {
      path: '/selectsmart/benefit-types';
      operationId: 'getEligibleBenefitTypes';
      variables: GetEligibleBenefitTypesVariables;
    }
  | {
      path: '/selectsmart/benefits/{benefitId}';
      operationId: 'getBenefit';
      variables: GetBenefitVariables;
    }
  | {
      path: '/selectsmart/benefit-uses';
      operationId: 'getBenefitUses';
      variables: GetBenefitUsesVariables;
    }
  | {
      path: '/selectsmart/benefit-uses/{benefitUseId}';
      operationId: 'getBenefitUse';
      variables: GetBenefitUseVariables;
    }
  | {
      path: '/selectsmart/bundles';
      operationId: 'getLatestBundles';
      variables: GetLatestBundlesVariables;
    }
  | {
      path: '/selectsmart/bundles/{bundlesId}';
      operationId: 'getBundles';
      variables: GetBundlesVariables;
    }
  | {
      path: '/selectsmart/bundle';
      operationId: 'getSelectedBundle';
      variables: GetSelectedBundleVariables;
    }
  | {
      path: '/selectsmart/accounts';
      operationId: 'getAccounts';
      variables: GetAccountsVariables;
    }
  | {
      path: '/selectsmart/accounts/{accountId}';
      operationId: 'getAccount';
      variables: GetAccountVariables;
    }
  | {
      path: '/selectsmart/goals';
      operationId: 'getGoals';
      variables: GetGoalsVariables;
    }
  | {
      path: '/selectsmart/goals/{goalType}';
      operationId: 'getGoal';
      variables: GetGoalVariables;
    }
  | {
      path: '/selectsmart/nextdollar';
      operationId: 'getLastestNextdollar';
      variables: GetLastestNextdollarVariables;
    }
  | {
      path: '/selectsmart/nextdollar/{nextdollarId}';
      operationId: 'getNextdollar';
      variables: GetNextdollarVariables;
    }
  | {
      path: '/selectsmart/actionplan';
      operationId: 'getActionplan';
      variables: GetActionplanVariables;
    }
  | {
      path: '/selectsmart/planbenefits';
      operationId: 'getPlanbenefits';
      variables: GetPlanbenefitsVariables;
    }
  | {
      path: '/selectsmart/planbenefits/{planbenefitId}';
      operationId: 'getPlanbenefit';
      variables: GetPlanbenefitVariables;
    }
  | {
      path: '/selectsmart/benefits-summary';
      operationId: 'getBenefitssummary';
      variables: GetBenefitssummaryVariables;
    }
  | {
      path: '/selectsmart/actions';
      operationId: 'getActions';
      variables: GetActionsVariables;
    }
  | {
      path: '/selectsmart/claims/summary';
      operationId: 'getClaimsSummary';
      variables: GetClaimsSummaryVariables;
    }
  | {
      path: '/selectsmart/ldi';
      operationId: 'getLdi';
      variables: GetLdiVariables;
    }
  | {
      path: '/selectsmart/summarized-benefits';
      operationId: 'getSummarizedBenefits';
      variables: GetSummarizedBenefitsVariables;
    }
  | {
      path: '/selectsmart/irs-limits';
      operationId: 'irsLimits';
      variables: IrsLimitsVariables;
    };
