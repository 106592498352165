import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Loading from './Loading';

type Props = {
  description?: string;
  positionKey?: string;
};

// Sync all animations for a cleaner better transition
const syncAnimationStart = Date.now();
// Track active animations for better smoothing
const active: { [key: string]: number } = {};

const PageActivityIndicator = ({ description, positionKey }: Props) => {
  // Fiddly code to smooth the transition between consecutive loading spinners
  // When there is no existing spinner with the given position key, we'll fade it in
  // If there was a recent spinner with the same key, we wont fade it in to keep a more
  // seamless transition. The goal is to:
  //   a) fade in new loading animations so they don't flash on quick transitions
  //   b) make consecutive loading elements (common on signup/signin/initial load scenarios)
  //      appear seamless and not jittery.
  useEffect(() => {
    if (positionKey) {
      active[positionKey] = active[positionKey] ? active[positionKey] + 1 : 1;
      return () => {
        // Grace period
        setTimeout(() => {
          active[positionKey]--;
        }, 100);
      };
    }
  }, []);

  // If the title is not set, set
  useEffect(() => {
    if (description && !document.title) {
      document.title = description;
    }
  }, [description]);

  return (
    <Box
      component={'main'}
      sx={{
        display: 'flex',
        height: '100%',
      }}>
      <Stack
        justifySelf="center"
        alignItems="center"
        spacing={2}
        direction="column"
        sx={{
          p: 6,
          flexGrow: 1,
          maxHeight: 400,
          minHeight: 100,
          animation:
            !positionKey || !active[positionKey] ? 'fadeIn 250ms' : null,
          position: 'relative',
        }}>
        <Loading startPosition={(Date.now() - syncAnimationStart) % 3600} />
        {description && (
          <Typography
            component={'h1'}
            role="status"
            align="center"
            mt={2}
            sx={{
              p: 2,
              borderRadius: '5px',
              background: 'rgba(255,255,255,0.7)',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}>
            {description}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default PageActivityIndicator;
